import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { isLoggedIn, getToken } from '@/auth/redux/reducer';
import checkToken from "@/helpers/checkToken";


const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const token = useSelector(state => getToken(state));
  const validateToken = checkToken(token);
  const LoggedIn = useSelector(state => isLoggedIn(state)) && !validateToken.error;
  const dispatch = useDispatch();
  
  return (
    <Route
      {...rest}
      render={props => {
        if (LoggedIn && restricted) {
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
        }

        dispatch({ type: 'LOGOUT_USER_SUCCESS' });
        return <Component {...props} />;
      }}
    />
)};

export default PublicRoute;
