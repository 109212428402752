import Immutable from 'seamless-immutable';

const { createActions, createReducer } = require('reduxsauce');

export const PATH = 'UploadPoolingReducer';

const { Types, Creators } = createActions({
  updateUpload: ['file', 'onFinish'],
  updateUploadSuccess: ['success'],
  updateUploadError: ['error'],

  clearImporterErrors: null,
  reset: null,
}, {prefix: `${PATH}/`});

export { Types };
export default Creators;

const INITIAL_STATE = Immutable({
  currentFile: null,
  pooling: false,
  error: null,
  success: false,

  isLoadingFile: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_UPLOAD]: (state, {file}) => {
    return {
      ...state,
      isLoadingFile: true,
      currentFile: file,
    };
  },
  [Types.UPDATE_UPLOAD_SUCCESS]: (state, { success }) => {
    return {
      ...state,
      isLoadingFile: false,
      success,
    };
  },
  [Types.UPDATE_UPLOAD_ERROR]: (state, { error }) => {
    return {
      ...state,
      isLoadingFile: false,
      error,
    };
  },

  [Types.CLEAR_IMPORTER_ERRORS]: (state) => {
    return {
      ...state,
      error: null,
    };
  },

  [Types.RESET]: (state) => {
    return {
      ...state,
      success: false,
    };
  }
});

export const getSuccess = (state) => state[PATH].success;
export const getError = (state) => state[PATH].error;