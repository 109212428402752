import cloneDeep from 'lodash.clonedeep';

export const fetchReports = (state, { activeDays }) => {
  return { ...state, loadingVisits: true, error: null, activeDays };
};

export const setReports = (state, { reports, date }) => {
  if (!date) {
    return state;
  }

  return {
    ...state,
    reports: {
      ...state.reports,
      [`${date}`]: reports,
    },
    lastUpdates: {
      ...state.lastUpdates,
      [`${date}`]: new Date().getTime(),
    },
  };
};

export const setError = (state) => {
  return { ...state, error: true };
};

export const setSearch = (state, { search }) => {
  return { ...state, search };
};

export const setCheckbox = (state, { checkbox }) => {
  return { ...state, checkbox };
};

export const updateReportDate = (state, { data }) => {
  const reportsClone = cloneDeep(state.reports);
  const { source, destination } = data;

  const removedSource = reportsClone[`${source.droppableId}`].splice(source.index, 1);

  reportsClone[`${destination.droppableId}`].splice(destination.index, 0, removedSource[0]);

  return { ...state, updatingReportsDate: true, reports: { ...reportsClone } };
};

export const updateReportDateSuccess = (state) => ({ ...state, updatingReportsDate: false });

export const updateReportDateError = (state, { data }) => {
  const reportsClone = cloneDeep(state.reports);
  const { source, destination } = data;

  const removedSource = reportsClone[`${destination.droppableId}`].splice(destination.index, 1);

  reportsClone[`${source.droppableId}`].splice(source.index, 0, removedSource[0]);

  return { ...state, updatingReportsDate: false, reports: { ...reportsClone } };
};

export const fetchExportFormulariesOptions = (state) => {
  return { ...state, loadingFormulariesOptions: true };
};
export const fetchExportFormulariesOptionsSuccess = (state, { formulariesOptions }) => {
  return { ...state, formulariesOptions, loadingFormulariesOptions: false };
};
export const fetchExportFormulariesOptionsError = (state) => {
  return { ...state, loadingFormulariesOptions: false };
};
