
import { notification } from 'antd';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import _ from 'lodash';
import { takeLatest, debounce, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

import { Types, Creators } from '../reducer';

function* syncSaga(date, filters) {
  const formatted = format(date, 'dd-MM-yyyy', { locale: ptBR });
  
  const { status, data } = yield call(api.get, `/reports?date=${formatted}`, { params: { filters } });
  
  if (status === 200 && data) {
    yield put(Creators.setReports(data, formatted));
  }
}

function* fetchReportsSaga(action) {
  try {
    const { activeDays, filters } = action;

    const retrievers = _.reduce(activeDays, (prev, date) => {
      if(!date) return prev;
      const retriever = call(syncSaga, date, filters)
      return [...prev, retriever]
    },[]);

    yield all(retrievers);

  } catch (e) {
    // yield put(Creators.setUserFailed());
  }
}

function* updateReportsDateSaga(action) {
  const {
    destination: { droppableId },
    id,
  } = action.data;

  try {
    const { status, data } = yield call(api.put, `/reports/${id}`, {
      scheduled_date: droppableId,
    });

    yield put(Creators.updateReportDateSuccess());
  } catch (e) {
    notification.error({
      message: 'Erro!',
      description: 'Ocorreu um erro ao mudar a data da visita. Tente novamente mais tarde...',
    });

    yield put(Creators.updateReportDateError({ ...action.data }));
  }
}

function* fetchFormulariesOptions(action) {
  try {
    const { data } = yield call(api, `/formulary_list`, {params: {form_type: "search"}});

    yield put(Creators.fetchExportFormulariesOptionsSuccess(data));
  } catch (e) {
    notification.error({
      message: 'Erro!',
      description: 'Ocorreu um erro ao buscar as opções de formulário. Tente novamente mais tarde...',
    });

    yield put(Creators.fetchExportFormulariesOptionsError());
  }
}

export default [
  debounce(300, Types.FETCH_REPORTS, fetchReportsSaga),
  takeLatest(Types.UPDATE_REPORT_DATE, updateReportsDateSaga),
  takeLatest(Types.FETCH_EXPORT_FORMULARIES_OPTIONS, fetchFormulariesOptions),
];
