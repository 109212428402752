/* eslint-disable import/no-cycle */
import _ from 'lodash';
import moment from 'moment';
import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import requestError from '@/helpers/requestError';
import mockFormularies from '@/mocks/formularies.json';
import mockSchedule from '@/mocks/schedule.json';
import mockStores from '@/mocks/stores.json';
import api from '@/services/api';

import Actions, { Types } from './reducer';

export function* syncScheduleSagas(action) {
  // Mock Schedule
  if (process.env.REACT_APP_MOCK === "true") {
    yield put(Actions.syncScheduleSuccess(
      {
        ...mockSchedule.scheduler, 
        date_start: moment(mockSchedule.scheduler.date_start, 'DD/MM/YYYY'), 
        date_end: moment(mockSchedule.scheduler.date_end, 'DD/MM/YYYY')
      },
      mockSchedule.selected_store_ids,
      mockSchedule.selected_formulary_ids,
    ));

    return yield put(Actions.setAssociatedStores(mockSchedule.scheduler.associate_stores));
  }

  try{
    const scheduleId = yield select(state => state.ScheduleEditReducer.scheduleId);
    
    const { data, status} = yield call(api.get, `/schedulers/${scheduleId}`, { params: {} });

    if (status === 200) {
      yield put(Actions.syncScheduleSuccess(
        {
          ...data.scheduler, 
          date_start: moment(data.scheduler.date_start, 'DD/MM/YYYY'), 
          date_end: (data.scheduler.date_end ? moment(data.scheduler.date_end, 'DD/MM/YYYY') : null)
        },
        data.selected_store_ids,
        data.selected_formulary_ids,
      ));
      
      return yield put(Actions.setAssociatedStores(data.scheduler.associate_stores));
    } 
    
    return yield put(Actions.syncScheduleError());

  } catch(error) {
    requestError(error)
    yield put(Actions.syncScheduleError());
  }
};

export function* syncStoresSagas(action) {
  // Mock Stores
  if (process.env.REACT_APP_MOCK === "true") {
    yield put(Actions.syncStoresSave(mockStores.stores, mockStores.ids));
    return yield put(Actions.syncStoresFinish());
  }

  try{
    const page = yield select(state => state.ScheduleEditReducer.pageStores);
    const { data, status} = yield call(api.get, 'schedulers/locals', { params: { page } });

    if (status === 200 && data.stores.length > 0) {
      return yield put(Actions.syncStoresSave(data.stores, data.ids, page));
    }

    return yield put(Actions.syncStoresFinish());
  } catch(error) {
    requestError(error)
    yield put(Actions.syncStoresFinish());
  }
};

export function* filterStores(action) {
  const data = yield select(state => state.ScheduleEditReducer);

  const stores = Array.from(Array(data.stores.length).keys());
  
  if (!data.searchStores && (data.storesType === 'all')) {
    return yield put(Actions.setIndexedStores(stores));
  }

  const indexedStores = [];
  
  stores.forEach(storeIndex => {
    let validate = true;

    const store = data.stores[storeIndex];
    
    if (data.searchStores) {
      const checkName = store.name ? !store.name.toLowerCase().includes(data.searchStores.toLowerCase()) : true;
      const checkAddress = store.address ? !store.address.toLowerCase().includes(data.searchStores.toLowerCase()) : true;
      const checkState = store.state ? !store.state.toLowerCase().includes(data.searchStores.toLowerCase()) : true;
      const checkCity = store.city ? !store.city.toLowerCase().includes(data.searchStores.toLowerCase()) : true;
      const checkRetail = store.retail ? !store.retail.toLowerCase().includes(data.searchStores.toLowerCase()) : true;
      const checkFlag = store.flag ? !store.flag.toLowerCase().includes(data.searchStores.toLowerCase()) : true;

      if (checkName && checkAddress && checkState && checkCity && checkRetail && checkFlag) {
        validate = false;
      }
    }

    if (data.storesType === 'selected' && !data.selectedStoreIds.includes(store.id)) {
      validate = false;
    }

    if (validate) indexedStores.push(storeIndex);
  });

  yield put(Actions.setIndexedStores(
    indexedStores
  ));
};

export function* syncFormulariesSagas(action) {
  // Mock Formularies
  if (process.env.REACT_APP_MOCK === "true") {
    return yield put(Actions.syncFormulariesSuccess(
      mockFormularies.formularies, 
      mockFormularies.ids
    ));
  }

  try{
    const { data, status} = yield call(api.get, 'schedulers/formularies', { params: {} });

    if (status === 200) {
      return yield put(Actions.syncFormulariesSuccess(
        data.formularies, 
        data.ids
      ));
    }

    return yield put(Actions.syncFormulariesError());
  } catch(error) {
    requestError(error)
    yield put(Actions.syncFormulariesError());
  }
};

export function* filterFormularies(action) {
  const data = yield select(state => state.ScheduleEditReducer);

  const formularies = Array.from(Array(data.formularies.length).keys());
  
  if (!data.searchFormularies && (data.formulariesType === 'all')) {
    return yield put(Actions.setIndexedFormularies(formularies));
  }

  const indexedFormularies = [];
  
  formularies.forEach(formularyIndex => {
    let validate = true;

    const formulary = data.formularies[formularyIndex];
    
    if (data.searchFormularies && !formulary.name.toLowerCase().includes(data.searchFormularies.toLowerCase())) {
      validate = false;
    }

    if (data.formulariesType === 'selected' && !data.selectedFormularyIds.includes(formulary.id)) {
      validate = false;
    }

    if (validate) indexedFormularies.push(formularyIndex);
  });

  yield put(Actions.setIndexedFormularies(
    indexedFormularies
  ));
};

export function* syncScheduleEdit(action) {
  const { scheduleId } = action;

  if (scheduleId !== 'new') {
    yield put(Actions.syncSchedule());
  } else {
    yield put(Actions.syncScheduleError());
  }

  yield put(Actions.syncStores());
  yield put(Actions.syncFormularies());
};

export function* sendSchedule(action) {
  const { data, successCallback } = action;

  // Mock Send Schedule
  if (process.env.REACT_APP_MOCK === "true") {
    yield put(Actions.sendScheduleFinish());
    if (successCallback) successCallback();
    return
  }

  try{
    const scheduleId = yield select(state => state.ScheduleEditReducer.scheduleId);

    if (scheduleId === 'new') {
      yield call(api.post, `/schedulers`, { ...data });
    } else {
      yield call(api.put, `/schedulers/${scheduleId}`, { ...data });
    }

    yield put(Actions.sendScheduleFinish());

    if (successCallback) successCallback();

  } catch(error) {
    requestError(error)
    yield put(Actions.sendScheduleFinish());
  }
};

export function* deleteSchedule(action) {
  const { scheduleId, successCallback } = action;

  // Mock Delete Schedule
  if (process.env.REACT_APP_MOCK === "true") {
    yield put(Actions.deleteScheduleFinish());
    if (successCallback) successCallback();
    return
  }

  try{
    const { status} = yield call(api.delete, `/schedulers/${scheduleId}`, { params: {} });

    if (status === 204 && successCallback) successCallback();

    return yield put(Actions.deleteScheduleFinish());

  } catch(error) {
    requestError(error)
    yield put(Actions.deleteScheduleFinish());
  }
};

export default function*() {
  yield all([
    takeLatest(Types.SET_SCHEDULE_ID, syncScheduleEdit),
    takeLatest(Types.SYNC_SCHEDULE, syncScheduleSagas),

    takeLatest(Types.SYNC_STORES, syncStoresSagas),
    takeLatest(Types.SYNC_STORES_SAVE, syncStoresSagas),
    takeLatest(Types.SET_SEARCH_STORES, filterStores),
    takeLatest(Types.SET_STORES_TYPE, filterStores),
    takeLatest(Types.SET_SELECTED_STORE_ID, filterStores),
    takeLatest(Types.DESELECT_ALL_STORES, filterStores),

    takeLatest(Types.SYNC_FORMULARIES, syncFormulariesSagas),
    takeLatest(Types.SET_SEARCH_FORMULARIES, filterFormularies),
    takeLatest(Types.SET_FORMULARIES_TYPE, filterFormularies),
    takeLatest(Types.SET_SELECTED_FORMULARY_ID, filterFormularies),
    takeLatest(Types.DESELECT_ALL_FORMULARIES, filterFormularies),

    takeLatest(Types.SEND_SCHEDULE, sendSchedule),
    takeLatest(Types.DELETE_SCHEDULE, deleteSchedule),
  ]);
};
