import { createReducer, createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    fetchUsers: ['params'],
    setUsers: ['users'],
    resetUsers: null,
    setError: null,
    setStartDate: ['startDate'],
    setVisitsMeta: ['id', 'value'],
    fetchVisitMeta: ['params'],
    fetchWeek: null,
    setWeekData: ['weekData'],
    stopRequisition: null,
  },
  {
    prefix: 'WeeklyVisits/',
  }
);

const INITIAL_STATE = {
  loadingVisits: false,
  visits: [],
  users: [],
  error: false,
  hasNextPage: false,
  visitsMeta: {},
  startDate: new Date(),
  weekData: [],
  loadingWeekData: false,
};

const fetchUsers = (state) => {
  return { ...state, loadingVisits: true };
};

const setUsers = (state, { users }) => {
  return {
    ...state,
    loadingVisits: false,
    users,
    hasNextPage: false,
  };
};

const setError = (state) => {
  return { ...state, loadingVisits: false, error: true };
};

const setVisitsMeta = (state, { id, value }) => {
  return { ...state, visitsMeta: { ...state.visitsMeta, [`${id}`]: value } };
};

const setStartDate = (state, { startDate }) => {
  return { ...state, startDate, users: [], visitsMeta: {}, weekData: [] };
};

const resetUsers = (state) => {
  return { ...state, users: [], hasNextPage: false };
};

const fetchWeek = (state) => {
  return { ...state, loadingWeekData: true };
};

const setWeekData = (state, { weekData }) => {
  return { ...state, weekData, loadingWeekData: false };
};

const HANDLERS = {
  [Types.FETCH_USERS]: fetchUsers,
  [Types.SET_USERS]: setUsers,
  [Types.SET_ERROR]: setError,
  [Types.STOP_REQUISITION]: (state) => ({ ...state }),
  [Types.SET_START_DATE]: setStartDate,
  [Types.SET_VISITS_META]: setVisitsMeta,
  [Types.RESET_USERS]: resetUsers,
  [Types.FETCH_WEEK]: fetchWeek,
  [Types.SET_WEEK_DATA]: setWeekData,
};

export default createReducer(INITIAL_STATE, HANDLERS);
