import antdEs from 'antd/lib/locale-provider/ca_ES';

import menu from './menu';

const esLang = {
  messages: {
    ...menu,
  },
  antd: antdEs,
  locale: 'es',
};

export default esLang;
