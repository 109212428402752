import _ from 'lodash';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  setScheduleId: ['scheduleId'],
  
  syncSchedule: null,
  syncScheduleSuccess: ['schedule', 'selectedStoreIds', 'selectedFormularyIds'],
  syncScheduleError: null,
  setAssociatedStores: ['associatedStores'],

  syncStores: null,
  syncStoresSave: ['stores', 'storeIds', 'pageStores'],
  syncStoresFinish: null,
  setStoresType: ['storesType'],
  setSearchStores: ['searchStores'],
  selectAllStores: null,
  deselectAllStores: null,
  setIndexedStores: ['indexedStores'],
  setSelectedStoreId: ['selectedStoreId'],

  syncFormularies: null,
  syncFormulariesSuccess: ['formularies', 'formularyIds'],
  syncFormulariesError: null,
  setFormulariesType: ['formulariesType'],
  setSearchFormularies: ['searchFormularies'],
  selectAllFormularies: null,
  deselectAllFormularies: null,
  setIndexedFormularies: ['indexedFormularies'],
  setSelectedFormularyId: ['selectedFormularyId'],

  sendSchedule: ['data', 'successCallback'],
  sendScheduleFinish: null,

  deleteSchedule: ['scheduleId', 'successCallback'],
  deleteScheduleFinish: null,

  resetState: null,
});

export { Types };
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  scheduleId: null,
  schedule: {},
  loadingSchedule: true,
  associatedStores: true,

  loadingStores: false,
  indexedStores: [],
  stores: [],
  storeIds: [],
  searchStores: '',
  storesType: 'all',
  selectedStoreIds: [],

  loadingFormularies: false,
  indexedFormularies: [],
  formularies: [],
  formularyIds: [],
  searchFormularies: '',
  formulariesType: 'all',
  selectedFormularyIds: [],

  loadingSendSchedule: false,
});

/* Hookup Reducers to Types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SCHEDULE_ID]: (state, { scheduleId }) => {
    return ({ ...INITIAL_STATE, scheduleId });
  },

  [Types.SET_ASSOCIATED_STORES]: (state, { associatedStores }) => {
    if (associatedStores) {
      return ({ ...state, associatedStores });
    } 
    
    return ({ ...state, associatedStores, selectedStoreIds: [] }); 
  },

  [Types.SYNC_SCHEDULE]: (state) => {
    return ({ ...state, schedule: {}, loadingSchedule: true });
  },
  [Types.SYNC_SCHEDULE_SUCCESS]: (state, { schedule, selectedStoreIds, selectedFormularyIds }) => {
    return ({ ...state, schedule, selectedStoreIds, selectedFormularyIds, loadingSchedule: false });
  },
  [Types.SYNC_SCHEDULE_ERROR]: (state) => {
    return ({ ...state, loadingSchedule: false });
  },

  [Types.SYNC_STORES]: (state) => {
    return ({ 
      ...state,
      loadingStores: true,
      indexedStores: [],
      stores: [],
      storeIds: [],
      pageStores: 1,
      searchStores: '',
      storesType: 'all',
      selectedStoreIds: [],
    });
  },
  [Types.SYNC_STORES_SAVE]: (state, { stores, storeIds, pageStores }) => {
    return ({ ...state, pageStores: (pageStores + 1), stores: [...state.stores, ...stores], storeIds: [...state.storeIds, ...storeIds] });
  },
  [Types.SYNC_STORES_FINISH]: (state) => {
    return ({ ...state, loadingStores: false, indexedStores: Array.from(Array(state.stores.length).keys()) });
  },
  [Types.SET_STORES_TYPE]: (state, { storesType }) => {
    return ({ ...state, storesType });
  },
  [Types.SET_SEARCH_STORES]: (state, { searchStores }) => {
    return ({ ...state, searchStores });
  },
  [Types.SET_SELECTED_STORE_ID]: (state, { selectedStoreId }) => {
    const currentIndex = state.selectedStoreIds.indexOf(selectedStoreId);
    const newChecked = [...state.selectedStoreIds];

    if (currentIndex === -1) {
      newChecked.push(selectedStoreId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    return ({ ...state, selectedStoreIds: newChecked });
  },
  [Types.SELECT_ALL_STORES]: (state) => {
    const ids = _.map(state.indexedStores, storeIndex => state.stores[storeIndex].id)

    return ({ ...state, selectedStoreIds:[ ...state.selectedStoreIds ,...ids] });
  },
  [Types.DESELECT_ALL_STORES]: (state) => {
    let newSelected = INITIAL_STATE.selectedStoreIds;

     if(!_.isEmpty(state.selectedStoreIds)) {
      const selectedIds = _.map(
        state.indexedStores, 
        storeIndex => state.stores[storeIndex].id
      );
      newSelected = _.difference(state.selectedStoreIds,selectedIds);
    }

    return ({ ...state, selectedStoreIds: newSelected });
  },
  [Types.SET_INDEXED_STORES]: (state, { indexedStores }) => {
    return ({ ...state, indexedStores });
  },

  [Types.SYNC_FORMULARIES]: (state) => {
    return ({ 
      ...state,
      loadingFormularies: true,
      indexedFormularies: [],
      formularies: [],
      formularyIds: [],
      searchFormularies: '',
      formulariesType: 'all',
      selectedFormularyIds: [],
    });
  },
  [Types.SYNC_FORMULARIES_SUCCESS]: (state, { formularies, formularyIds }) => {
    return ({ 
      ...state,
      formularies,
      formularyIds,
      loadingFormularies: false,
      indexedFormularies: Array.from(Array(formularies.length).keys())
    });
  },
  [Types.SYNC_FORMULARIES_ERROR]: (state) => {
    return ({ ...state, loadingFormularies: false });
  },
  [Types.SET_FORMULARIES_TYPE]: (state, { formulariesType }) => {
    return ({ ...state, formulariesType });
  },
  [Types.SET_SEARCH_FORMULARIES]: (state, { searchFormularies }) => {
    return ({ ...state, searchFormularies });
  },
  [Types.SET_SELECTED_FORMULARY_ID]: (state, { selectedFormularyId }) => {
    const currentIndex = state.selectedFormularyIds.indexOf(selectedFormularyId);
    const newChecked = [...state.selectedFormularyIds];

    if (currentIndex === -1) {
      newChecked.push(selectedFormularyId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    return ({ ...state, selectedFormularyIds: newChecked });
  },
  [Types.SELECT_ALL_FORMULARIES]: (state) => {
    const ids = state.indexedFormularies.map(formularyIndex => state.formularies[formularyIndex].id)

    return ({ ...state, selectedFormularyIds: ids });
  },
  [Types.DESELECT_ALL_FORMULARIES]: (state) => {

    return ({ ...state, selectedFormularyIds: [] });
  },
  [Types.SET_INDEXED_FORMULARIES]: (state, { indexedFormularies }) => {
    return ({ ...state, indexedFormularies });
  },

  [Types.SEND_SCHEDULE]: (state) => {
    return ({ ...state, loadingSendSchedule: true });
  },
  [Types.SEND_SCHEDULE_FINISH]: (state) => {
    return ({ ...state, loadingSendSchedule: false });
  },

  [Types.DELETE_SCHEDULE]: (state) => {
    return ({ ...state, loadingSchedule: true });
  },
  [Types.DELETE_SCHEDULE_FINISH]: (state) => {
    return ({ ...state, loadingSchedule: false });
  },

  [Types.RESET_STATE]: (state) =>  ({ ...INITIAL_STATE }),
});

/* Selectors */
export const loadingSchedule = state => state.ScheduleEditReducer.loadingSchedule;
export const getSchedule = state => state.ScheduleEditReducer.schedule;
export const getAssociatedStores = state => state.ScheduleEditReducer.associatedStores;

export const loadingStores = state => state.ScheduleEditReducer.loadingStores;
export const indexedStores = state => state.ScheduleEditReducer.indexedStores;
export const getStore = (state, index) => state.ScheduleEditReducer.stores[index];
export const totalStores = state => state.ScheduleEditReducer.indexedStores.length;
export const checkSelectedStore = (state, id) => state.ScheduleEditReducer.selectedStoreIds.includes(id);
export const getSelectedStoreIds = state => state.ScheduleEditReducer.selectedStoreIds;

export const loadingFormularies = state => state.ScheduleEditReducer.loadingFormularies;
export const indexedFormularies = state => state.ScheduleEditReducer.indexedFormularies;
export const getFormulary = (state, index) => state.ScheduleEditReducer.formularies[index];
export const totalFormularies = state => state.ScheduleEditReducer.indexedFormularies.length;
export const checkSelectedFormulary = (state, id) => state.ScheduleEditReducer.selectedFormularyIds.includes(id);
export const getSelectedFormularyIds = state => state.ScheduleEditReducer.selectedFormularyIds;

export const loadingSendSchedule = state => state.ScheduleEditReducer.loadingSendSchedule;