/* eslint-disable import/no-cycle */
import React from 'react';

import { notification } from 'antd';
import { all, takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';

import {
  fixedInitialAnswers,
  dynamicInitialAnswers,
  normalizeAnswers,
} from '@/helpers/manipulateAnswers';
import requestError from '@/helpers/requestError';
import api from '@/services/api';

import Actions, { Types, path } from './reducer';

export function* syncItemSagas(action) {
  try {
    const itemId = yield select((state) => state[path].itemId);
    // const contentType = yield select(state => state[path].contentType);
    // const baseType = `${contentType}s`;

    const [store, forms, curationCategories] = yield all([
      call(api.get, `/visits/${itemId}`, { params: {} }),
      call(api.get, `/visits/${itemId}/formularies`, { params: {} }),
      call(api.get, '/curations_categories'),
    ]);

    if (store.status === 200 && forms.status === 200 && curationCategories.status === 200) {
      yield put(Actions.syncItemSuccess(store.data, forms.data, curationCategories.data));

      if (forms.data && forms.data.length > 0) {
        yield put(Actions.setForm(forms.data[0]));
      }

      return;
    }

    yield put(Actions.syncItemError());
  } catch (error) {
    requestError(error);
    yield put(Actions.syncItemError());
  }
}

export function* updateItem(action) {
  yield put(Actions.syncItem());
}

export function* fetchStoreToEdit(action) {
  const { visitId } = action.data;
  try {
    const store = yield call(api.get, `/visits/${visitId}`, { params: {} });
    if (store.status === 200) {
      const storeToEdit = store.data;
      yield put(Actions.fetchStoreToEditSuccess(storeToEdit));
      return;
    }
    yield put(Actions.fetchStoreToEditError());
  } catch (error) {
    yield put(Actions.fetchStoreToEditError());
  }
}

export function* syncFormsSagas(action) {
  try {
    const itemId = yield select((state) => state[path].itemId);
    const form = yield select((state) => state[path].form);

    const { data, status } = yield call(api, `/visits/${itemId}/formularies`);

    if (form && form.id) {
      const newForm = data.find((f) => f.id === form.id);

      if (newForm && newForm.form_options && newForm.form_options.length > 0)
        yield put(Actions.setForm(newForm));
    }

    if (status === 200) return yield put(Actions.syncFormsSuccess(data));

    yield put(Actions.syncFormsError());
  } catch (error) {
    requestError(error);
    yield put(Actions.syncFormsError());
  }
}

export function* syncAnswersSagas(action) {
  try {
    const { form, itemId } = yield select((state) => state[path]);
    // const contentType = yield select(state => state[path.contentType]);

    const params = form.item_id ? { item_id: form.item_id } : {};

    const { data, status } = yield call(
      api,
      `/visits/${itemId}/formularies/${form.form_id}/formulary_answers`,
      { params }
    );

    if (status === 200) {
      let initialValues = {};

      if (Array.isArray(data)) {
        initialValues = fixedInitialAnswers(data, itemId, form.form_id);
        return yield put(Actions.syncAnswersSuccess(data, initialValues));
      }

      if (data.products) {
        initialValues = fixedInitialAnswers(data.products, itemId, form.form_id);
        return yield put(Actions.syncAnswersSuccess(data, initialValues));
      }

      Object.entries(data).forEach(([i, v]) => {
        const formattedValues = dynamicInitialAnswers(v, itemId, form.form_type, i);
        initialValues = { ...initialValues, ...formattedValues };
      });

      return yield put(Actions.syncAnswersSuccess(data, initialValues));
    }

    yield put(Actions.syncFormsError());
  } catch (error) {
    requestError(error);
    yield put(Actions.syncFormsError());
  }
}

export function* updateVisitDate(action) {
  const { plannedCheckIn, plannedCheckOut, visitId, date } = action.data;
  try {
    const { status, data } = yield call(api.put, `/visits/${visitId}`, {
      scheduled_date: date,
      scheduled_time_begin: plannedCheckIn,
      scheduled_time_end: plannedCheckOut,
    });

    notification.success({
      message: 'Sucesso!',
      description: 'Edição da data e horário feita com sucesso!',
    });

    yield call(updateForm);
  } catch (e) {
    notification.error({
      message: 'Erro!',
      description: 'Ocorreu um erro ao mudar a data da visita. Tente novamente mais tarde...',
    });

    yield put(Actions.updateCheckDateAndHourError({ ...action.data }));
  }
}

export function* updateAnswers(action) {
  const { form } = action;

  if (!form) return;

  yield put(Actions.syncAnswers());

  if (form && form.form_options && form.form_options.length > 0) {
    yield put(Actions.setFormOption(form.form_options[0]));
  }
}

export function* saveCuration(action) {
  try {
    const { categories, comment, cleanCuration } = action;
    const { itemId, curations, form } = yield select((state) => state[path]);

    const { status } = yield call(api.post, `/curations`, {
      form_id: form.form_id,
      visit_id: itemId,
      curation_categories_ids: categories,
      comment,
      question_ids: curations,
    });

    yield put(Actions.saveCurationFinish());

    if (status === 200) {
      yield put(Actions.syncForms());
      yield put(Actions.syncAnswers());
    }

    if (cleanCuration) cleanCuration();
  } catch (error) {
    requestError(error);
    yield put(Actions.saveCurationFinish());
  }
}

export function* saveAnswers(action) {
  try {
    const { changeAnswers } = action;
    const { itemId, form } = yield select((state) => state[path]);
    const answers = normalizeAnswers(changeAnswers);

    const url = `/visits/${itemId}/formularies/${form.form_id}`;

    const { status } = yield call(api.put, url, { answers });

    yield put(Actions.saveAnswersFinish());

    if (status === 200) {
      yield put(Actions.syncAnswers());
    }
  } catch (error) {
    requestError(error);
    yield put(Actions.saveAnswersFinish());
  }
}

export function* saveJustification(action) {
  try {
    const { approved, comment, cleanJustification } = action;
    const { itemId, form } = yield select((state) => state[path]);

    yield call(api.post, `/justifications_histories`, {
      survey_task_id: itemId,
      survey_item_id: form.item_id,
      approved,
      comment,
    });

    yield put(Actions.saveJustificationFinish());

    if (cleanJustification) cleanJustification();
  } catch (error) {
    requestError(error);
    yield put(Actions.saveJustificationFinish());
  }
}

export function* updateForm(action) {
  yield put(Actions.syncItem());
}

export default function*() {
  yield all([
    takeLatest(Types.SYNC_ITEM, syncItemSagas),
    takeLatest(Types.SET_ITEM_ID, updateItem),

    takeLatest(Types.SYNC_FORMS, syncFormsSagas),

    takeLatest(Types.SYNC_ANSWERS, syncAnswersSagas),
    takeLatest(Types.SET_FORM, updateAnswers),

    takeEvery(Types.UPDATE_CHECK_DATE_AND_HOUR, updateVisitDate),

    takeLatest(Types.SAVE_CURATION, saveCuration),

    takeLatest(Types.FETCH_STORE_TO_EDIT, fetchStoreToEdit),

    takeLatest(Types.SAVE_ANSWERS, saveAnswers),

    takeLatest(Types.SAVE_JUSTIFICATION, saveJustification),
    takeLatest(Types.SAVE_JUSTIFICATION_FINISH, updateForm),
  ]);
}
