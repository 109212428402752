import { compareAsc } from 'date-fns';
import _ from 'lodash';

// parameters should be on 'dd-MM-yyy' format. Ex: 25-05-2022.
// it will return -1 if firstDate is smaller than secoundDate, 0 if
// they are equal or 1 if it's bigger

export default function compareDates(firstDate, secoundDate) {
  return compareAsc(
    new Date(
      _.toInteger(firstDate.slice(6)),
      _.toInteger(firstDate.slice(3, 5)) - 1,
      _.toInteger(firstDate.slice(0, 2))
    ),
    new Date(
      _.toInteger(secoundDate.slice(6)),
      _.toInteger(secoundDate.slice(3, 5)) - 1,
      _.toInteger(secoundDate.slice(0, 2))
    )
  );
}
