import { all, takeLatest, put, call } from 'redux-saga/effects';

import requestError from '@/helpers/requestError';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';
// import { registerDevice, unregisterDevice } from '@/services/fcm-api';

import { Types } from './reducer';

export function* loginUser(action) {
  const { data: { username, password } } = action;

  try{
    const response = yield call(api.post, '/authenticate', { username, password, origin: 'browser' });

    if(response.status === 200){
      const { auth_token: authToken, user_infos: { user }, role } = response.data;

      const userData = {
        ...user,
        password,
      };

      yield put({
        type: Types.LOGIN_USER_SUCCESS,
        token: authToken,
        role,
        userData,
      });
    }
  }catch(error) {
    requestError(error)

    yield put({
      type: Types.LOGIN_USER_FAILED,
      error: '',
    });
  }

};

export function* registerUser(action) {
  // create register
};

export function* logoutUser() {

  yield put({ type: Types.LOGOUT_USER_SUCCESS});

  yield put({
    type: 'RESET_STATE',
  });
};

export default function*() {
  yield all([
    takeLatest(Types.LOGIN_USER, loginUser),
    takeLatest(Types.REGISTER_USER, registerUser),
    takeLatest(Types.LOGOUT_USER, logoutUser),
  ]);
};
