import cloneDeep from 'lodash.clonedeep';
import { createReducer, createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    fetchVisits: ['activeDays', 'filters', 'forceFetch', 'flagDay'],
    setVisits: ['visits', 'date', 'deleteDate'],
    setSearchText: ['searchText'],
    setCheckbox: ['checkbox'],
    setTypeCheckbox: ['typeCheckbox'],
    setJustificationCheckbox: ['checkbox'],
    setCurationCheckbox: ['curationCheckbox'],
    setCurationForms: ['curationForms'],
    setError: null,
    fetchCurationForm: [],
    fetchCurationFormSuccess: ['curationOptions'],
    fetchCurationFormError: null,

    updateCheckDateAndHour: ['data'],
    updateCheckDateAndHourError: null,

    fetchJustificationOptions: null,
    fetchJustificationOptionsSuccess: ['options'],
    fetchJustificationOptionsError: null,

    fetchExportFormulariesOptions: null,
    fetchExportFormulariesOptionsSuccess: ['formulariesOptions'],
    fetchExportFormulariesOptionsError: null,

    setDifferenceDates: ['data'],
  },
  { prefix: 'visits/' }
);

const INITIAL_STATE = {
  visits: {},
  activeDays: [],
  flagDay: null,
  lastUpdates: {},
  failedActions: [],
  checkbox: null,
  curationCheckbox: {},
  curationForms: [],
  searchText: '',
  error: null,
  updatingVisitDate: false,
  curationOptions: [],
  loadingCurationOptions: false,
  loadingStore: false,
  typeCheckbox: null,
  justificationCheckbox: null,
  loadingVisits: false,
  differenceDates: [],
  loadedVisits: [],

  justificationOptions: [],
  errorJustification: null,
  loadingJustification: false,

  formulariesOptions: [],
  loadingFormulariesOptions: false,
};

const fetchVisits = (state, { activeDays, flagDay }) => {
  return {
    ...state,
    loadingVisits: true,
    error: null,
    activeDays,
    flagDay,
    loadedVisits: [],
    differenceDates: [],
  };
};

const setVisits = (state, { visits, date, deleteDate }) => {
  if (!date) {
    return state;
  }

  if (deleteDate === 'delete') delete state.visits[Object.keys(state.visits)[0]];
  else if (deleteDate !== 'create') delete state.visits[deleteDate];

  state.loadedVisits.push(date);

  return {
    ...state,
    visits: {
      ...state.visits,
      [`${date}`]: visits,
    },
    lastUpdates: {
      ...state.lastUpdates,
      [`${date}`]: new Date().getTime(),
    },
    loadingVisits: false,
  };
};

const setError = (state) => {
  return { ...state, error: true };
};

const setSearchText = (state, { searchText }) => {
  return { ...state, searchText };
};

const setCheckbox = (state, { checkbox }) => {
  return { ...state, checkbox };
};

const setCurationCheckbox = (state, { curationCheckbox }) => {
  return { ...state, curationCheckbox };
};

const setCurationForms = (state, { curationForms }) => {
  return { ...state, curationForms };
};

const fetchCurationForm = (state) => {
  return { ...state, loadingCurationOptions: true };
};

const fetchCurationFormSuccess = (state, { curationOptions }) => {
  return { ...state, loadingCurationOptions: false, curationOptions };
};

const fetchCurationFormError = (state) => {
  return { ...state, loadingCurationOptions: false };
};
const setTypeCheckbox = (state, { typeCheckbox }) => {
  return { ...state, typeCheckbox };
};

const setJustificationCheckbox = (state, { checkbox }) => {
  return { ...state, justificationCheckbox: checkbox };
};

const updateCheckDateAndHour = (
  state,
  { plannedCheckIn, plannedCheckOut, visitId, date, setIsModalOpen }
) => {
  return { ...state, loadingSaveDateAndHour: true };
};

const updateCheckDateAndHourError = (state) => {
  return {
    ...state,
    loadingSaveDateAndHour: false,
  };
};

const fetchJustificationOptions = (state) => {
  return { ...state, loadingJustification: true, errorJustification: null };
};

const fetchJustificationOptionsSuccess = (state, { options }) => {
  return { ...state, justificationOptions: options, loadingJustification: false };
};

const fetchJustificationOptionsError = (state) => {
  return { ...state, errorJustification: true, loadingJustification: false };
};

const setDifferenceDates = (state, { data }) => {
  return { ...state, differenceDates: data };
};

const fetchExportFormulariesOptions = (state) => {
  return { ...state, loadingFormulariesOptions: true };
};
const fetchExportFormulariesOptionsSuccess = (state, { formulariesOptions }) => {
  return { ...state, formulariesOptions, loadingFormulariesOptions: false };
};
const fetchExportFormulariesOptionsError = (state) => {
  return { ...state, loadingFormulariesOptions: false };
};

const HANDLERS = {
  [Types.FETCH_VISITS]: fetchVisits,
  [Types.SET_VISITS]: setVisits,
  [Types.SET_ERROR]: setError,
  [Types.SET_SEARCH_TEXT]: setSearchText,
  [Types.SET_CHECKBOX]: setCheckbox,
  [Types.SET_CURATION_CHECKBOX]: setCurationCheckbox,
  [Types.SET_CURATION_FORMS]: setCurationForms,
  [Types.FETCH_CURATION_FORM]: fetchCurationForm,
  [Types.FETCH_CURATION_FORM_SUCCESS]: fetchCurationFormSuccess,
  [Types.FETCH_CURATION_FORM_ERROR]: fetchCurationFormError,
  [Types.SET_TYPE_CHECKBOX]: setTypeCheckbox,
  [Types.SET_JUSTIFICATION_CHECKBOX]: setJustificationCheckbox,
  [Types.UPDATE_CHECK_DATE_AND_HOUR]: updateCheckDateAndHour,
  [Types.UPDATE_CHECK_DATE_AND_HOUR_ERROR]: updateCheckDateAndHourError,
  [Types.FETCH_JUSTIFICATION_OPTIONS]: fetchJustificationOptions,
  [Types.FETCH_JUSTIFICATION_OPTIONS_SUCCESS]: fetchJustificationOptionsSuccess,
  [Types.FETCH_JUSTIFICATION_OPTIONS_ERROR]: fetchJustificationOptionsError,
  [Types.SET_DIFFERENCE_DATES]: setDifferenceDates,
  [Types.FETCH_EXPORT_FORMULARIES_OPTIONS]: fetchExportFormulariesOptions,
  [Types.FETCH_EXPORT_FORMULARIES_OPTIONS_SUCCESS]: fetchExportFormulariesOptionsSuccess,
  [Types.FETCH_EXPORT_FORMULARIES_OPTIONS_ERROR]: fetchExportFormulariesOptionsError,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const PATH = 'VisitsReducer';

// SECTION SELECTORS

const root = (selector) => {
  return (state) => state[PATH][selector];
};

export const getExportFormulariesOptions = {
  formulariesOptions: root('formulariesOptions'),
  loadingFormulariesOptions: root('loadingFormulariesOptions'),
};
