import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';
// import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  font-family: ${font('base')};
  background: ${palette('component_background', 0)};

  .ant-pro-basicLayout-children-content-wrap {
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: ${palette('scrollbar_color', 0)};
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb {
    background: ${palette('primary_color', 0)};
    width: 6px;
  }

  .ant-btn {
    &:enabled {
      &:hover {
        border-color: ${palette('primary_color', 0)} !important;
        color: ${palette('primary_color', 0)} !important;
      }

      &:focus {
        border-color: ${palette('primary_color', 0)} !important;
        color: ${palette('primary_color', 0)} !important;
      }
    }
  }

  .ant-btn-primary {
    &:enabled {
      background-color: ${palette('primary_color', 0)} !important;
      border-color: ${palette('primary_color', 0)} !important;
      color: #fff !important;

      &:hover {
        background-color: #fac73e !important;
        border-color: #fac73e !important;
        color: #fff !important;
      }

      &:focus {
        background-color: ${palette('primary_color', 0)} !important;
        border-color: ${palette('primary_color', 0)} !important;
        color: #fff !important;
      }
    }
  }

  .ant-select, .ant-select-open {
    .ant-select-selector {
      &:hover {
        border-color: ${palette('primary_color', 0)} !important;
        box-shadow: 0 0 0 2px ${palette('input_shadow', 0)} !important;
      }
    }
  }

  .ant-select-open {
    border-color: ${palette('primary_color', 0)} !important;
    box-shadow: 0 0 0 2px ${palette('input_shadow', 0)} !important;
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${palette('primary_color', 0)} !important;
    box-shadow: 0 0 0 2px ${palette('input_shadow', 0)} !important;
  }

  .search-container {
    &:hover {
      border-color: ${palette('primary_color', 0)} !important;
    }
  }

  .ant-input-affix-wrapper {
    &:hover {
      border-color: ${palette('primary_color', 0)} !important;
    }

    .ant-input-number:focus,
    .ant-input-number-focused,
    .ant-input:focus,
    .ant-input-focused {
      box-shadow: 0 0 0 2px transparent !important;
    }
  }

  .ant-input-affix-wrapper-focused {
    border-color: ${palette('primary_color', 0)} !important;
    box-shadow: 0 0 0 2px transparent !important;
  }

  .ant-radio-group-outline {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: ${palette('primary_color', 0)} !important;
      border-color: ${palette('primary_color', 0)} !important;

      &::before {
        background-color: ${palette('primary_color', 0)} !important;
      }
    }

    .ant-radio-button-wrapper {
      &:hover {
        color: ${palette('primary_color', 0)} !important;
      }
  }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${palette('primary_color', 0)} !important;
  }

  .ant-tabs-ink-bar {
    background: ${palette('primary_color', 0)} !important;
  }

  .ant-tabs-tab-btn:focus {
    color: ${palette('primary_color', 0)} !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${palette('primary_color', 0)} !important;
    border-color: ${palette('primary_color', 0)} !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox-wrapper-checked:hover .ant-checkbox-inner {
    border: 1px solid ${palette('hover_color', 0)} !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid ${palette('hover_color', 0)} !important;
  }

  .ant-checkbox::hover {
    border-color: ${palette('hover_color', 0)} !important;
  }

  .ant-radio-inner {
    border-color: ${palette('primary_color', 0)} !important;

    &::after {
      background-color: ${palette('primary_color', 0)} !important;
    }
  }

  .ant-switch-checked {
    background: ${palette('primary_color', 0)} !important;
  }

  .ant-upload.ant-upload-select-picture-card:hover {
    border-color: ${palette('hover_color', 0)} !important;
  }

  .ant-input-number:focus,
  .ant-input-number-focused,
  .ant-input:focus,
  .ant-input-focused {
    box-shadow: 0 0 0 2px ${palette('input_shadow', 0)} !important;
  }

  .search-container {
    .ant-input-number:focus,
    .ant-input-number-focused,
    .ant-input:focus,
    .ant-input-focused {
      box-shadow: 0 0 0 2px transparent !important;
    }
  }

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: ${palette('hover_color', 0)} !important;
  }

  .ant-picker:hover, .ant-picker-focused {
    border-color: ${palette('hover_color', 0)} !important;
  }
`;

export default GlobalStyles;
