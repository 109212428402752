import _, { isEmpty } from 'lodash';
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions(
  {
    syncUsers: null,
    syncUsersSuccess: ['users'],
    syncUsersError: null,

    setSearchUsers: ['searchUsers'],
    setIndexedUsers: ['indexedUsers'],
    setAssociatedStores: ['associatedStores'],

    setUserStoreCodes: ['storeCodes'],

    syncUsersStores: null,
    syncStoresSuccess: ['stores', 'storesCodes'],
    syncStoresError: null,

    editCurrentUser: ['currentUser', 'userCode'],
    editCurrentUserSuccess: null,
    editCurrentUserError: null,

    setStoresType: ['storesType'],
    setSearchStores: ['searchStores'],
    selectAllUsersStores: null,
    deselectAllUsersStores: null,
    setIndexedStores: ['indexedStores'],
    setSelectedStoreCode: ['selectedStoreCode'],

    clearData: null,

    setFilterValues: ['filterValues'],
    syncCurrentUser: ['userId'],
    syncCurrentUserSucess: ['currentUser'],
    syncCurrentUserError: null,
    setEditFormStatus: ['status'],

    resetStateUsers: null,
    resetUserLogin: ['resetUserId'],

    libraryImport: ['values', 'onSuccess', 'onFailure'],
    libraryImportSuccess: null,
    libraryImportError: null,

    syncRoles: null,
    syncRolesSuccess: ['roles'],
    syncRolesError: null,

    onlyFileImport: ['fileUrl', 'importerType', 'onSuccess', 'onFailure'],
    onlyFileImportSuccess: null,
    onlyFileImportError: null,
  },
  { prefix: 'UserReducer/' }
);

export { Types };
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loadingUsers: false,
  loadingFormularies: false,
  indexedUsers: [],
  users: [],
  user: {},

  stores: [],
  associatedStores: true,
  searchStores: '',
  storesType: 'all',
  selectedStoreCodes: [],
  indexedStores: [],
  loadingStores: false,
  storeCodes: [],

  loadingUser: false,
  currentUser: {},
  editStatus: false,
  searchUsers: '',

  // filterOptions: [],
  filterValues: {},
  filterKeys: {
    id: { name: 'Id', group: 'general' },
    name: { name: 'Nome', group: 'general' },
    roles: { name: 'Cargo', group: 'general' },
    version: { name: 'Versão do App', group: 'general' },
    devices: { name: 'Modelo do Aparelho', group: 'general' },
    operatorCompany: { name: 'Operadora', group: 'general' },
    androidVersion: { name: 'Versão do Android', group: 'general' },
  },

  resetUserId: 0,

  loadingImport: false,
  roles: [],
  loadingRoles: false,
});

/* Hookup Reducers to Types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNC_USERS]: (state) => {
    return { ...state, filterValues: {}, filterOptions: [], loadingUsers: true };
  },
  [Types.SYNC_USERS_SUCCESS]: (state, { users }) => {
    return {
      ...state,
      users,
      loadingUsers: false,
      indexedUsers: Array.from(Array(users.length).keys()),
    };
  },
  [Types.SYNC_USERS_ERROR]: (state) => {
    return { ...state, loadingUsers: false };
  },

  [Types.SET_USER_STORE_CODES]: (state, { storeCodes }) => {
    return { ...state, selectedStoreCodes: storeCodes };
  },
  [Types.SYNC_USERS_STORES]: (state) => {
    return {
      ...state,
      loadingStores: true,
      indexedStores: [],
      searchStores: '',
      storesType: 'all',
      selectedStoreCodes: [],
    };
  },

  [Types.SYNC_STORES_SUCCESS]: (state, { stores, storeCodes }) => {
    return {
      ...state,
      loadingStores: false,
      storeCodes,
      stores,
      indexedStores: Array.from(Array(stores.length).keys()),
    };
  },
  [Types.SYNC_STORES_ERROR]: (state) => {
    return { ...state, loadingStores: false };
  },

  [Types.SET_SEARCH_STORES]: (state, { searchStores }) => {
    return { ...state, searchStores };
  },
  [Types.SET_STORES_TYPE]: (state, { storesType }) => {
    return { ...state, storesType };
  },
  [Types.SELECT_ALL_USERS_STORES]: (state) => {
    const codes = _.map(state.indexedStores, (storeIndex) => state.stores[storeIndex].code);

    return { ...state, selectedStoreCodes: [...state.selectedStoreCodes, ...codes] };
  },
  [Types.DESELECT_ALL_USERS_STORES]: (state) => {
    let newSelected = INITIAL_STATE.selectedStoreCodes;

    if (!_.isEmpty(state.selectedStoreCodes)) {
      const selectedCodes = _.map(
        state.indexedStores,
        (storeIndex) => state.stores[storeIndex].code
      );
      newSelected = _.difference(state.selectedStoreCodes, selectedCodes);
    }

    return { ...state, selectedStoreCodes: newSelected };
  },
  [Types.SET_SELECTED_STORE_CODE]: (state, { selectedStoreCode }) => {
    const currentIndex = state.selectedStoreCodes.indexOf(selectedStoreCode);
    const newChecked = [...state.selectedStoreCodes];

    if (currentIndex === -1) {
      newChecked.push(selectedStoreCode);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    return { ...state, selectedStoreCodes: newChecked };
  },
  [Types.SET_SEARCH_USERS]: (state, { searchUsers }) => {
    return { ...state, searchUsers };
  },
  [Types.SET_INDEXED_USERS]: (state, { indexedUsers }) => {
    return { ...state, indexedUsers };
  },
  [Types.SET_INDEXED_STORES]: (state, { indexedStores }) => {
    return { ...state, indexedStores };
  },
  [Types.SET_FILTER_VALUES]: (state, { filterValues }) => {
    return { ...state, filterValues: _.pickBy(filterValues) };
  },

  [Types.SYNC_CURRENT_USER]: (state) => {
    return { ...state, loadingUser: true };
  },
  [Types.SYNC_CURRENT_USER_SUCESS]: (state, { currentUser }) => {
    return { ...state, currentUser, loadingUser: false };
  },
  [Types.SYNC_CURRENT_USER_ERROR]: (state) => {
    return { ...state, loadingUser: false };
  },
  [Types.SET_EDIT_FORM_STATUS]: (state, { status }) => {
    return { ...state, editStatus: status };
  },

  [Types.EDIT_CURRENT_USER]: (state) => {
    return { ...state, loadingSendForm: true };
  },
  [Types.EDIT_CURRENT_USER_SUCCESS]: (state) => {
    return { ...state, loadingSendForm: false };
  },
  [Types.EDIT_CURRENT_USER_ERROR]: (state) => {
    return { ...state, loadingSendForm: false };
  },

  [Types.CLEAR_DATA]: (state) => {
    return {
      ...state,
      currentUser: {},
      selectedStoreCodes: [],
      searchStores: '',
    };
  },
  [Types.RESET_STATE_USERS]: (state) => ({ ...INITIAL_STATE }),
  [Types.RESET_USER_LOGIN]: (state, { resetUserId }) => {
    return { ...state, resetUserId };
  },

  [Types.ONLY_FILE_IMPORT]: (state) => {
    return { ...state, loadingImport: true };
  },
  [Types.ONLY_FILE_IMPORT_SUCCESS]: (state) => {
    return { ...state, loadingImport: false };
  },
  [Types.ONLY_FILE_IMPORT_ERROR]: (state) => {
    return {
      ...state,
      loadingImport: false,
    };
  },
  [Types.SYNC_ROLES]: (state) => {
    return { ...state, loadingRoles: true };
  },
  [Types.SYNC_ROLES_SUCCESS]: (state, { roles }) => {
    return {
      ...state,
      roles,
      loadingRoles: false,
    };
  },
  [Types.SYNC_ROLES_ERROR]: (state) => {
    return { ...state, loadingRoles: false };
  },
  [Types.LIBRARY_IMPORT]: (state) => {
    return { ...state, loadingImport: true };
  },
  [Types.LIBRARY_IMPORT_SUCCESS]: (state) => {
    return { ...state, loadingImport: false };
  },
  [Types.LIBRARY_IMPORT_ERROR]: (state) => {
    return { ...state, loadingImport: false };
  },
  
});

export const PATH = 'UsersReducer';

const root = (selector) => {
  return (state) => state[PATH][selector];
};

/* Selectors */

export const getMain = {
  loadingUsers: (state) => state[PATH].loadingUsers,
  emptyUsers: (state) => state[PATH].users <= 0,
  indexedUsers: (state) => state[PATH].indexedUsers,
  getUsers: (state) => state[PATH].users,
  getUser: (state, index) => state[PATH].users[index],
  totalUsers: (state) => state[PATH].indexedUsers?.length,
  getLoadingForm: (state) => state[PATH].loadingSendForm,
  getRoles: (state) => state[PATH].roles,
  loadingRoles: (state) => state[PATH].loadingRoles,
};
export const getStores = {
  getStore: (state, index) => state[PATH].stores[index],
  checkSelectedStore: (state, id) => state[PATH].selectedStoreCodes.includes(id),
  indexedStores: (state) => state[PATH].indexedStores,
  totalStores: (state) => state[PATH].indexedStores.length,
  loadingFormularies: (state) => state[PATH].loadingFormularies,
  loadingStores: (state) => state[PATH].loadingStores,
};

export const getUser = {
  name: (state) => state[PATH].name,
  role: (state) => state[PATH].role,
  version: (state) => state[PATH].version,
  device: (state) => state[PATH].device,
  getCurrentUser: (state) => state[PATH].currentUser,
  loadingFormUser: (state) => state[PATH].loadingUser,
  editStatus: (state) => state[PATH].editStatus,
  operatorCompany: (state) => state[PATH].operatorCompany,
  androidVersion: (state) => state[PATH].androidVersion,
};

export const filterKeys = (state) => state[PATH].filterKeys;
export const filterValues = (state) => state[PATH].filterValues;
