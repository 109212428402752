/* eslint-disable camelcase */
import { rgba, hsl } from 'polished';

import { sum, subtract, multiply, divide, round } from '@/helpers/polishedUtil';

const theme = {};

/* Colors */

theme.palette = {
  primary_color: ['#F0B310'],
  antd_color: ['#1890ff'],
  highlight_color: ['#148C98'],
  warning_color: ['#faad14'],
  normal_color: ['#d9d9d9'],
  white: ['#ffffff'],
  black: ['#000000'],
  side_border: ['#f0f0f0'],
  background_color: ['#ffffff'],
  scrollbar_color: ['#e1e7e9'],
  dark_background_color: ['#EFF1F4'],
  light_background_color: ['#F8F8F8'],
  light_background_color_hover: ['#DCDCDC'],
  layout_body_background: ['#f0f2f5'],
  background_scrollbar: [rgba('#000000', 0.06)],
  item_hover_bg: ['#f5f5f5'],
  hover_color: ['#fac73e'],
  border_color_inverse: ['#ffffff'],
  info_color: ['#F27059'],
  processing_color: ['#F27059'],
  icon_color: ['inherit'],
  link_decoration: ['none'],
  link_hover_decoration: ['none'],
  box_shadow: [rgba('#9AA1AB', 0.15)],
  border_color_base: [`${hsl(0, 0, 0.85)}`],
  border_color_split: [`${hsl(0, 0, 0.94)}`],
  input_shadow: [rgba('#F0B310', 0.15)],
  primary: [
    "#05BDBD", // 0
    "#05B3B3", // 1
    "#049F9F", // 2
    "#048B8B", // 3
    "#037777", // 4
    "#036363", // 5
    "#025050", // 6
    "#023C3C", // 7
    "#023C3C", // 8
    "#012828", // 9
  ],
  grayscale: [
    '#bababa', // 0
    '#c1c1c1', // 1
    '#D8D8D8', // 2
    '#f1f1f1', // 3
    '#F3F3F3', // 4
    '#fafafa', // 5
    '#F9F9F9', // 6
    '#fcfcfc', // 7
    '#eeeeee', // 8
    '#fbfbfb', // 9
    '#f5f5f5', // 10
    '#f7f8f9', // 11
  ],
  text: [
    '#788195', // 0
    '#595959', // 1
    '#797979', // 2
    '#000000a6', // 3
  ],
  border: [
    '#e9e9e9', // 0
    '#d8d8d8', // 1
    '#ebebeb', // 2
    '#d3d3d3', // 3
  ],
  error_color: [
    '#f64744', // 0
    '#EC3D3A', // 1
    '#FF5B58', // 2
    '#e66562', // 3
    '#f647449c' // 4
  ],
  success_color: [
    '#2fa960', // 0
    '#5ebb83', // 1
  ],
};

const { primary: [ ,p1,,,p4,,p6,,, ], primary_color, black, white, layout_body_background } = theme.palette;

theme.palette.layout_trigger_background = ['red'];
theme.palette.layout_trigger_color = [white[0]];;
theme.palette.layout_header_background = [primary_color[0]];
theme.palette.layout_sider_background = [primary_color[0]];
theme.palette.layout_footer_background = [layout_body_background[0]];
theme.palette.component_background = [white[0]];
theme.palette.link_hover_color = [p4];
theme.palette.link_active_color = [p6];
theme.palette.disabled_color = [rgba(black[0], 0.25)];
theme.palette.text_color = [rgba(black[0], 0.65)];
theme.palette.text_color_dark = [rgba(white[0], 0.85)];
theme.palette.menu_item_color = [rgba(black[0], 0.65)];
theme.palette.menu_dark_submenu_bg = [white[0]];
theme.palette.menu_dark_arrow_color = [rgba(black[0], 0.65)];
theme.palette.menu_dark_selected_item_text_color = [rgba(black[0], 0.65)];
theme.palette.menu_highlight_color = [primary_color[0]];
theme.palette.menu_item_active_bg = [p1];
theme.palette.menu_item_group_title_color = [rgba(black[0], 0.45)];
theme.palette.text_color_secondary = [rgba(black[0], 0.45)];
theme.palette.icon_color_hover = [rgba(black[0], 0.75)];
theme.palette.heading_color = [rgba(black[0], 0.85)];
theme.palette.heading_color_dark = [rgba(white[0], 1.00)];
theme.palette.text_color_dark = [rgba(white[0], 0.85)];
theme.palette.text_color_secondary_dark = [rgba(white[0], 0.65)];


/* Fonts */

theme.fonts = {
  base: 'Roboto',
  code: 'SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace',
  feature_settings: 'tnum',
  variant: 'tabular-nums',
};


/* Sizes */

theme.sizes = {
  menu_item_height: '40px',
  menu_collapsed_width: '80px',
  menu_item_boundary_margin: '8px',
  menu_item_vertical_margin: '4px',
  menu_item_active_border_width: '3px',
  menu_inline_toplevel_item_height: '40px',
  font_size_base: '14px',
  line_height_base: 1.5715,
  border_radius_base: '2px',
  border_width_base: '1px',
  layout_trigger_height: '48px',
  layout_zero_trigger_width: '36px',
  layout_zero_trigger_height: '42px',
  outline_blur_size: 0,
  outline_width: '2px',
  padding_3xl: '36px',
  padding_2xl: '32px',
  padding_xl: '28px',
  padding_lg: '24px',
  padding_md: '16px',
  padding_sm: '12px',
  padding_xs: '8px',
  screen_xs: '480px',
  screen_sm: '576px',
  screen_md: '768px',
  screen_lg: '992px',
  screen_xl: '1200px',
  screen_xxl: '1600px',
  zindex_dropdown: 1050,
}

const { menu_collapsed_width, border_radius_base, font_size_base, screen_xs, screen_sm , screen_md, screen_lg, screen_xl, screen_xxl } = theme.sizes;

theme.sizes.layout_header_height = '64px';
theme.sizes.layout_header_padding = '0 50px';
theme.sizes.layout_footer_padding = '24px 50px';
theme.sizes.menu_item_font_size = font_size_base;
theme.sizes.menu_icon_size = font_size_base;
theme.sizes.menu_icon_size_lg = sum(font_size_base, '2px');
theme.sizes.layout_menu_logo = `0 ${divide(2, subtract(menu_collapsed_width, '32px'))}`;
theme.sizes.border_radius_sm = round('ceil', divide(2, border_radius_base));
theme.sizes.font_size_xs = subtract(font_size_base, '4px');
theme.sizes.font_size_sm = subtract(font_size_base, '2px');
theme.sizes.font_size_lg = sum(font_size_base, '2px');
theme.sizes.font_size_xl = round('ceil', multiply(1.42, font_size_base));
theme.sizes.font_size_2xl = round('ceil', multiply(1.71, font_size_base));
theme.sizes.font_size_3xl = round('ceil', multiply(2.14, font_size_base));
theme.sizes.font_size_4xl = round('ceil', multiply(2.71, font_size_base));
theme.sizes.font_size_5xl = round('ceil', multiply(3.14, font_size_base));
theme.sizes.font_size_6xl = round('ceil', multiply(3.71, font_size_base));
theme.sizes.font_size_7xl = round('ceil', multiply(4.14, font_size_base));
theme.sizes.font_size_8xl = round('ceil', multiply(4.71, font_size_base));
theme.sizes.font_size_9xl = round('ceil', multiply(5.14, font_size_base));
theme.sizes.font_size_10xl = round('ceil', multiply(5.71, font_size_base));
theme.sizes.screen_xs_min = screen_xs;
theme.sizes.screen_sm_min = screen_sm;
theme.sizes.screen_md_min = screen_md;
theme.sizes.screen_lg_min = screen_lg;
theme.sizes.screen_xl_min = screen_xl;
theme.sizes.screen_xxl_min = screen_xxl;
theme.sizes.screen_xs_max = subtract(screen_sm, '1px');
theme.sizes.screen_sm_max = subtract(screen_md, '1px');
theme.sizes.screen_md_max = subtract(screen_lg, '1px');
theme.sizes.screen_lg_max = subtract(screen_xl, '1px');
theme.sizes.screen_xl_max = subtract(screen_xxl, '1px');

export default theme;
