import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  syncStatusDay: ['userId', 'userName', 'currentDate'],
  syncStatusDaySuccess: ['timelineCategories', 'timelineData', 'mapsRoute', 'mapsPoints', 'comparativeData', 'comparativeTabs'],
  syncStatusDayError: null,

  resetState: null,
});

export { Types };
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loadingStatusDay: false,

  statusVisible: false,

  userId: null,
  userName: null,
  currentDate: null
,
  timelineCategories: {},
  timelineData: [],

  mapsRoute: [],
  mapsPoints: [],

  comparativeData: {},
  comparativeTabs: []
});

/* Hookup Reducers to Types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNC_STATUS_DAY]: (state, { userId, userName, currentDate }) => {
    return ({ ...state, userId, userName, currentDate, statusVisible: true, loadingStatusDay: true });
  },
  [Types.SYNC_STATUS_DAY_SUCCESS]: (state, { timelineCategories, timelineData, mapsRoute, mapsPoints, comparativeData, comparativeTabs }) => {
    return ({ ...state, timelineCategories, timelineData, mapsRoute, mapsPoints, comparativeData, comparativeTabs, loadingStatusDay: false });
  },
  [Types.SYNC_STATUS_DAY_ERROR]: (state) => {
    return ({ ...state, statusVisible: false, userId: null, userName: null, loadingStatusDay: false });
  },

  [Types.RESET_STATE]: (state) =>  ({ ...INITIAL_STATE }),
});

/* Selectors */
export const loadingStatusDay = state => state.StatusDayReducer.loadingStatusDay;
export const statusVisible = state => state.StatusDayReducer.statusVisible;

export const getUserId = state => state.StatusDayReducer.userId;
export const getUserName = state => state.StatusDayReducer.userName;
export const getCurrentDate = state => state.StatusDayReducer.currentDate;

export const getTimelineData = state => state.StatusDayReducer.timelineData;
export const getTimelineCategories = state => state.StatusDayReducer.timelineCategories;

export const getMapsRoute = state => state.StatusDayReducer.mapsRoute;
export const getMapsPoints = state => state.StatusDayReducer.mapsPoints;

export const getComparativeData = state => state.StatusDayReducer.comparativeData;
export const getComparativeTabs = state => state.StatusDayReducer.comparativeTabs;