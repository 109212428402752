import { useDispatch } from "react-redux";

import axios from 'axios';

import { Types } from "@/auth/redux/reducer";

// eslint-disable-next-line import/no-cycle
import { store } from '../store';

export const create = (url) => {
  const api = axios.create({
    baseURL: url,
  });

  api.interceptors.request.use(
    (reqConfig) => {
      const { auth: { token: accessToken } } = store.getState();
      reqConfig.headers.Authorization = `Bearer ${accessToken}`;
  
      return reqConfig;
    },
    (error) => {
      if(error.response.status === 401 ){
        const dispatch = useDispatch();
  
        dispatch({type: Types.LOGOUT_USER});
        window.location.pathname = '/';
      }
      return Promise.reject(error);
    },
  );

  return api;
};

export default create(process.env.REACT_APP_BASE_URL);
