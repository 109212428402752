import { notification } from 'antd';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

import Actions, { Types } from './reducer';

export function* checkUpload(action) {
  const {
    file: { id },
    onFinish,
  } = action;
  try {
    const { data } = yield call(api.get, `/importers/${id}.json`);
    yield put(Actions.updateUploadSuccess(data?.success));

    data?.success && notification.success({
      message: 'Sucesso!',
      description: 'O processo foi concluído sem erros.',
      placement: 'topRight',
    });
    data?.success && onFinish({ closeImporter: true });
  } catch (error) {
    const customErrors = error.response?.data?.errors || null;
    const customErrorsMessage = 'Houveram erros encontrado. Você poderá identificar os erros clicando em "Ver erros da importação"';
    const defaultErrorsMessage = 'Não conseguimos identificar uma respostas do processamento.'
    notification.error({
      message: 'Houveram alguns problemas!',
      description: customErrors ? customErrorsMessage : defaultErrorsMessage,
      placement: 'topRight',
    });
    yield put(Actions.updateUploadError(customErrors || defaultErrorsMessage));
    onFinish({ closeImporter: false });
  }
}

export default function*() {
  yield all([takeLatest(Types.UPDATE_UPLOAD, checkUpload)]);
}
