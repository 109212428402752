import React from 'react';

import PrivateRoute from '@/routes/PrivateRoute';
import { HomeOutlined, ScheduleOutlined, CarryOutOutlined, FileSearchOutlined, PictureOutlined, UsergroupDeleteOutlined, FileDoneOutlined } from '@ant-design/icons';

const Visits = React.lazy(() => import('@/containers/Visits'));
const Gallery = React.lazy(() => import('@/containers/Gallery'));
const VisitManagement = React.lazy(() => import('@/containers/VisitManagement'));
const VisitManagementDaily = React.lazy(() => import('@/containers/VisitManagementDaily'));
const Form = React.lazy(() => import('@/containers/Form'));
const Schedules = React.lazy(() => import('@/containers/Schedules'));
const ScheduleEdit = React.lazy(() => import('@/containers/ScheduleEdit'));
const Reports = React.lazy(() => import('@/containers/Reports'));
const Users = React.lazy(() => import('@/containers/Users'));
const Justifications = React.lazy(() => import('@/containers/Justifications'));

const appRoutes = [
  {
    path: ['/visits/:visitId', '/reports/:reportId'],
    label: 'visits_form',
    key: 'visits_form',
    component: Form,
    hideInMenu: true,
    route: PrivateRoute,
    restricted: true,
  },
 
  {
    path: '/visits',
    label: 'visits',
    key: 'visits',
    name: 'Visitas',
    icon: <HomeOutlined />,
    component: Visits,
    route: PrivateRoute,
    restricted: true,
  },
  {
    path: '/gallery',
    label: 'gallery',
    key: 'gallery',
    name: 'Galeria de fotos',
    icon: <PictureOutlined />,
    component: Gallery,
    route: PrivateRoute,
    restricted: true,
  },
  // {
  //   path: '/visits_by_week',
  //   label: 'visits_by_week',
  //   key: 'visits_by_week',
  //   name: 'Controle semanal de visitas',
  //   icon: <CarryOutOutlined />,
  //   component: VisitManagement,
  //   route: PrivateRoute,
  //   restricted: true,
  // },
  {
    path: '/visits_daily',
    label: 'visits_daily',
    key: 'visits_daily',
    name: 'Controle diário de visitas',
    icon: <CarryOutOutlined />,
    component: VisitManagementDaily,
    route: PrivateRoute,
    restricted: true,
  },
  {
    path: '/schedules/:scheduleId',
    name: 'Edição de agendamentos',
    label: 'schedules.edit',
    key: 'schedules.edit',
    component: ScheduleEdit,
    route: PrivateRoute,
    restricted: true,
  },
  {
    path: '/schedules',
    name: 'Agendamentos',
    label: 'schedules',
    key: 'schedules',
    icon: <ScheduleOutlined />,
    component: Schedules,
    route: PrivateRoute,
    restricted: true,
  },
  {
    path: '/reports',
    name: 'Pesquisas',
    label: 'reports',
    key: 'reports',
    icon: <FileSearchOutlined />,
    component: Reports,
    route: PrivateRoute,
    restricted: true,
  },
 
  {
    path: '/users',
    name: 'Usuários',
    label: 'users',
    key: 'users',
    icon: <UsergroupDeleteOutlined />,
    component: Users,
    route: PrivateRoute,
    restricted: true,
  },

  {
    path: '/justifications',
    name: 'Justificativas',
    label: 'justifications',
    key: 'justifications',
    icon: <FileDoneOutlined />,
    component: Justifications,
    route: PrivateRoute,
    restricted: true,
  },
  
];

export default appRoutes;
