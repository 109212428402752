import React from 'react';
import ReactDOM from 'react-dom';

// import { firebaseService } from '@/services/Firebase';


import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();

// firebaseService.requestFCMPermission();
