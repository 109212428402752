import update from 'immutability-helper';
import moment from 'moment';
import { createReducer, createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchPictures: ['filters'],
  setPictures: ['pictures', 'total'],
  setDateFilter: ['dateFilter'],
  setCurrentPage: ['currentPage'],
  setError: null,
  deletePictureStart: ['id', 'index'],
  deletePicture: ['index'],
  updatePicture: ['index', 'temporaryFileUrl'],
  setOriginType: ['originType'],
  resetPictures: null,
});

const INITIAL_STATE = {
  loadingPictures: false,
  pictures: [],
  dateFilter: { startDate: moment() },
  currentPage: 1,
  error: null,
  total: 0,
  originType: 'answers'
};

const fetchPictures = (state) => {
  return { ...state, loadingPictures: true, error: null };
};

const setPictures = (state, { pictures, total }) => {
  return {
    ...state,
    loadingPictures: false,
    pictures,
    total,
  };
};

const setDateFilter = (state, { dateFilter }) => {
  return { ...state, dateFilter, currentPage: 1, pictures: [] };
};

const setCurrentPage = (state, { currentPage }) => {
  return { ...state, currentPage };
};

const setError = (state) => {
  return { ...state, error: true, loadingPictures: false };
};

const deletePicture = (state, { index }) => {
  const updatedPictures = update(state.pictures, { $splice: [[index, 1]] });
  return { ...state, error: true, loadingPictures: false, pictures: updatedPictures };
};

const updatePicture = (state, { index, temporaryFileUrl }) => {
  const updatedPictures = update(state.pictures, {
    [index]: { temporary_file_url: { $set: temporaryFileUrl } },
  });

  return { ...state, error: true, loadingPictures: false, pictures: updatedPictures };
};

const resetPictures = (state) => {
  return { ...state, pictures: [], currentPage: 1 };
};

const setOriginType = (state, { originType }) => {
  return { ...state, originType, currentPage: 1, pictures: [] };
};

const HANDLERS = {
  [Types.FETCH_PICTURES]: fetchPictures,
  [Types.SET_PICTURES]: setPictures,
  [Types.SET_DATE_FILTER]: setDateFilter,
  [Types.SET_CURRENT_PAGE]: setCurrentPage,
  [Types.SET_ERROR]: setError,
  [Types.DELETE_PICTURE]: deletePicture,
  [Types.UPDATE_PICTURE]: updatePicture,
  [Types.SET_ORIGIN_TYPE]: setOriginType,
  [Types.RESET_PICTURES]: resetPictures,
};

/* Selectors */
export const getOriginType = state => state.GalleryReducer.originType;
export const getDateFilter = state => state.GalleryReducer.dateFilter;

export default createReducer(INITIAL_STATE, HANDLERS);
