/* eslint-disable import/no-cycle */
import React from 'react';

import { notification } from 'antd';
import _ from 'lodash';
import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import requestError from '@/helpers/requestError';
import mockVisits from '@/mocks/visits.json';
import api from '@/services/api';

import Actions, { Types, getMain } from './reducer';

export function* syncVisits(action) {
  // Mock Visits
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(Actions.syncVisitsSuccess(mockVisits.data));
  }

  const dates = yield select(getMain.getDates);
  const filters = yield select(getMain.getFilters);

  const page = 1;

  try {
    const { data } = yield call(
      api.get,
      `/tasks?scheduled_dates[]="${dates[0]}"&scheduled_dates[]="${dates[1]}"`,
      { params: { filters, page } }
    );

    yield put(Actions.syncVisitsSuccess(data));
  } catch (error) {
    requestError(error);
    yield put(Actions.syncVisitsError());
  }
}

function* fetchJustificationOptions(action) {
  try {
    const { status, data } = yield call(api, `/justification_formularies`);

    if (status === 200 && data) {
      yield put(Actions.fetchJustificationOptionsSuccess(data));
    }
  } catch (e) {
    notification.error({
      message: 'Erro!',
      description: 'Ocorreu um erro ao buscar as justificativas. Tente novamente mais tarde...',
    });

    yield put(Actions.fetchJustificationOptionsError());
  }
}

function* saveJustifications(action) {
  const { data, callback } = action;

  try {
    yield call(api.post, `/justification_formularies`, data);

    yield put(Actions.saveJustificationsSuccess());
    notification.success({
      message: 'Sucesso!',
      description: 'As visitas foram justificativas com sucesso!.',
    });

    setTimeout(() => {
      callback();
    }, 500);
  } catch (e) {
    notification.error({
      message: 'Erro!',
      description: 'Ocorreu um erro ao buscar as justificativas. Tente novamente mais tarde...',
    });

    yield put(Actions.saveJustificationsError());
  }
}

function* curationJustifications(action) {
  const { data, callback } = action;
  const visits = yield select(getMain.getVisits);
  const selectedVisitsIds = yield select(getMain.getJustificationIds);
  const filteredVisits = visits.filter((visit) => selectedVisitsIds.includes(visit.id));

  try {
    const requests = filteredVisits.map((task) =>
      call(api.post, `/justifications_histories`, {
        survey_task_id: task.id,
        survey_item_id: task.survey_item_id,
        approved: data.approved,
        comment: data.comment || '',
      })
    );

    yield all(requests);

    yield put(Actions.curationJustificationsSuccess());
    notification.success({
      message: 'Sucesso!',
      description: `Sucesso ao ${data.approved ? 'aceitar' : 'recusar'} a(s) justificativa(s)!`,
    });

    setTimeout(() => {
      callback(null, true);
    }, 500);
  } catch (e) {
    console.log('Error:', e.message || e);

    notification.error({
      message: 'Erro!',
      description: 'Ocorreu um erro. Tente novamente mais tarde...',
    });

    yield put(Actions.curationJustificationsError());
  }
}

function* cancelJustifications(action) {
  const selectedVisitsIds = yield select(getMain.getJustificationIds);

  try {
    const requests = selectedVisitsIds.map((taskId) =>
      call(api.patch, `visits_promoters/${taskId}/cancel_justification`)
    );

    yield all(requests);

    yield put(Actions.cancelJustificationsSuccess());
    notification.success({
      message: 'Sucesso!',
      description: 'Sucesso ao cancelar a(s) justificativa(s)!',
    });

    yield setTimeout(() => {
      action.callback();
    }, 1200);
  } catch (e) {
    notification.error({
      message: 'Erro!',
      description: 'Ocorreu um erro. Tente novamente mais tarde...',
    });

    yield put(Actions.cancelJustificationsError());
  }
}

export default function*() {
  yield all([
    takeLatest(Types.SYNC_VISITS, syncVisits),
    takeLatest(Types.FETCH_JUSTIFICATION_OPTIONS, fetchJustificationOptions),
    takeLatest(Types.SAVE_JUSTIFICATIONS, saveJustifications),
    takeLatest(Types.SET_FILTER_VALUES, syncVisits),
    takeLatest(Types.CURATION_JUSTIFICATIONS, curationJustifications),
    takeLatest(Types.CANCEL_JUSTIFICATIONS, cancelJustifications),
  ]);
}
