import antdBr from 'antd/lib/locale-provider/pt_BR';

import menu from './menu';

const brLang = {
  messages: {
    ...menu,
  },
  antd: antdBr,
  locale: 'br',
};

export default brLang;
