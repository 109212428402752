import _ from 'lodash';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  syncSchedules: null,
  syncSchedulesSuccess: ['schedules'],
  syncSchedulesError: null,

  setSearchSchedules: ['searchSchedules'],
  setIndexedSchedules: ['indexedSchedules'],

  setFilterSchedulesValues: ['filterValues'],
  setFilterOptions: ['filterOptions'],

  resetStateSchedule: null,
}, {prefix: 'SchedulesReducer/'});

export { Types };
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loadingSchedules: false,
  indexedSchedules: [],
  schedules: [],

  searchSchedules: '',

  filterOptions: [],
  filterSchedulesValues: {},
  filterKeys: {
    "type":             { name: "Tipo", group: "general", type: "checkbox" },
    "status":           { name: "Situação", group: "general", type: "checkbox"},
    "formularies":      { name: "Formulários", group: "general_array", type: "checkbox" },
    "regions":          { name: "Regiões", group: "general_array", type: "checkbox" },
    "states":           { name: "Estados", group: "general_array", type: "checkbox" },
    "cities":           { name: "Cidades", group: "general_array", type: "checkbox" },
    "retails":          { name: "Redes", group: "general_array", type: "checkbox" },
    "flags":            { name: "Bandeiras", group: "general_array", type: "checkbox" },
    "users":            { name: "Usuários", group: "general_array", type: "checkbox" },
  },
});

/* Hookup Reducers to Types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNC_SCHEDULES]: (state) => {
    return ({ ...state, filterValues: {}, filterOptions: [], loadingSchedules: true });
  },
  [Types.SYNC_SCHEDULES_SUCCESS]: (state, { schedules }) => {
    return ({ ...state, schedules, loadingSchedules: false, indexedSchedules: Array.from(Array(schedules.length).keys()) });
  },
  [Types.SYNC_SCHEDULES_ERROR]: (state) => {
    return ({ ...state, loadingSchedules: false });
  },

  [Types.SET_SEARCH_SCHEDULES]: (state, { searchSchedules }) => {
    return ({ ...state, searchSchedules });
  },
  [Types.SET_INDEXED_SCHEDULES]: (state, { indexedSchedules }) => {
    return ({ ...state, indexedSchedules });
  },

  [Types.SET_FILTER_OPTIONS]: (state, { filterOptions }) => {
    return ({ ...state, filterOptions });
  },
  [Types.SET_FILTER_SCHEDULES_VALUES]: (state, { filterValues }) => {
    return ({ ...state, filterValues: _.pickBy(filterValues) });
  },

  [Types.RESET_STATE_SCHEDULE]: (state) => ({ ...INITIAL_STATE }),
});

/* Selectors */
export const loadingSchedules = state => state.SchedulesReducer.loadingSchedules;
export const emptySchedules = state => state.SchedulesReducer.schedules <= 0;
export const indexedSchedules = state => state.SchedulesReducer.indexedSchedules;
export const getSchedule = (state, index) => state.SchedulesReducer.schedules[index];
export const totalSchedules = state => state.SchedulesReducer.indexedSchedules.length;

export const filterOptions = state => state.SchedulesReducer.filterOptions;
export const filterKeys = state => state.SchedulesReducer.filterKeys;
export const emptyFilterOptions = state => state.SchedulesReducer.filterOptions <= 0;
export const filterSchedulesValues = state => state.SchedulesReducer.filterSchedulesValues;