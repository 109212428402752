/* eslint-disable no-console */
import jwtDecode from 'jwt-decode';

const checkToken = (token) => {
  if (!token) {
    return { error: 'not matched' };
  }

  try {
    const decoded = jwtDecode(token);
    const expiredAt = decoded.expiredAt || decoded.exp * 1000;

    if (expiredAt > new Date().getTime()) {
      return {
        ...decoded,
        token,
        expiredAt: new Date(expiredAt),
      };
    }
      return { error: 'Token expired' };

  } catch (e) {
    console.log(`[CHECK TOKEN]: ${e}`);
    return { error: 'Server Error' };
  }
};

export default checkToken;
