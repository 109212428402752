/* eslint-disable import/no-cycle */
import { takeLatest, call, select, put } from 'redux-saga/effects';

import api from '@/services/api';

import { Types, Creators } from './reducer';

function* picturesApi(dateFilter, originType, currentPage, filters) {
  const scheduledDate = [dateFilter.startDate.format('DD/MM/YYYY')]

  const { status, data } = yield call(api, `/gallery?page=${currentPage}`, {
    params: {
      origin_type: originType,
      filters: {...filters, date: { scheduled_date: scheduledDate } },
    },
  });

  if (status === 200 && data) {
    yield put(Creators.setPictures(data.data, data.total));
  }
}

function* fetchPictures(action) {
  const dateFilter = yield select((state) => state.GalleryReducer.dateFilter);
  const currentPage = yield select((state) => state.GalleryReducer.currentPage);
  const originType = yield select((state) => state.GalleryReducer.originType);

  try {
    const { filters } = action;
    yield call(picturesApi, dateFilter, originType, currentPage, filters);
  } catch (e) {
    yield put(Creators.setError());
  }
}

export default [takeLatest(Types.FETCH_PICTURES, fetchPictures)];
