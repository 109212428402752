/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-syntax */
import { notification } from 'antd';
import { format, add, sub, compareAsc } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import _, { filter } from 'lodash';
import { debounce, call, all, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import formsMock from '@/mocks/formularies.json';
import visitsMock from '@/mocks/visits.json';
import api from '@/services/api';

import compareDates from '../../../helpers/compareDates';
import { Types, Creators } from './reducer';

function* fetchAndPopulate(date, filters, remove) {
  // const lastUpdate = yield select((state) => (state.VisitsReducer.lastUpdates[`${formatted}`]));
  // const now = new Date().getTime();
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(Creators.setVisits(visitsMock.visits, date));
  }

  // if(!lastUpdate || (lastUpdate && now - lastUpdate >= 30000) || override){
  const { status, data } = yield call(api, `/visits?date=${date}`, { params: { filters } });

  if (status === 200 && data && remove === 'smaller') {
    const deleteDate = add(
      new Date(
        _.toInteger(date.slice(6)),
        _.toInteger(date.slice(3, 5)) - 1,
        _.toInteger(date.slice(0, 2))
      ),
      { days: 7 }
    );
    yield put(Creators.setVisits(data, date, format(deleteDate, 'dd-MM-yyyy')));
  } else if (status === 200 && data && remove === 'bigger') {
    const deleteDate = sub(
      new Date(
        _.toInteger(date.slice(6)),
        _.toInteger(date.slice(3, 5)) - 1,
        _.toInteger(date.slice(0, 2))
      ),
      { days: 7 }
    );
    yield put(Creators.setVisits(data, date, format(deleteDate, 'dd-MM-yyyy')));
  } else if (status === 200 && data) {
    yield put(Creators.setVisits(data, date, remove));
  }
  // }
}

function* fetchVisits({ activeDays, filters, forceFetch = false }) {
  const onFormattedDate = (date) => format(date, 'dd-MM-yyyy', { locale: ptBR });
  try {
    const visits = yield select((state) => state.VisitsReducer.visits);
    const formattedDateDays = activeDays.map((day) => onFormattedDate(day));

    const changedDates = _.difference(formattedDateDays, Object.keys(visits));
    const daysChanged = !_.isEmpty(changedDates)

    const differenceDates = daysChanged
      ? changedDates
      : formattedDateDays;
    yield put(Creators.setDifferenceDates(differenceDates));

    for (const date of differenceDates) {
      if (forceFetch) {
        yield call(fetchAndPopulate, date, filters, 'create');
      } else {
        const compare =
          !_.isEmpty(Object.keys(visits)) &&
          compareDates(date, Object.keys(visits)[0]) === -1 &&
          compareDates(date, Object.keys(visits)[5]) === -1
            ? 'smaller'
            : 'bigger';

        if (_.isEmpty(Object.keys(visits))) {
          yield call(fetchAndPopulate, date, filters, 'create');
        } else if (differenceDates.length > 6)
          yield call(fetchAndPopulate, date, filters, 'delete');
        else yield call(fetchAndPopulate, date, filters, compare);
      }
    }
  } catch (e) {
    // yield put(Creators.setUserFailed());
  }
}

function* fetchCurationFormSaga(action) {
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(Creators.fetchCurationFormSuccess(formsMock.curation));
  }

  try {
    const { status, data } = yield call(api.get, '/visits_formularies', { params: {} });

    if (status === 200) {
      return yield put(Creators.fetchCurationFormSuccess(data));
    }
  } catch (error) {
    return yield put(Creators.fetchCurationFormError());
  }
}

function* fetchJustificationOptions(action) {
  try {
    const { status, data } = yield call(api, `/justification_reasons`);

    if (status === 200 && data) {
      yield put(Creators.fetchJustificationOptionsSuccess(data));
    }
  } catch (e) {
    notification.error({
      message: 'Erro!',
      description: 'Ocorreu um erro ao buscar as justificativas. Tente novamente mais tarde...',
    });

    yield put(Creators.fetchJustificationOptionsError());
  }
}

function* fetchFormulariesOptions(action) {
  try {
    const { data } = yield call(api, `/formulary_list`);

    yield put(Creators.fetchExportFormulariesOptionsSuccess(data));
  } catch (e) {
    notification.error({
      message: 'Erro!',
      description:
        'Ocorreu um erro ao buscar as opções de formulário. Tente novamente mais tarde...',
    });

    yield put(Creators.fetchExportFormulariesOptionsError());
  }
}

export function* updateVisitDate(action) {
  const { plannedCheckIn, plannedCheckOut, visitId, date, setIsModalOpen } = action.data;
  try {
    const { status, data } = yield call(api.put, `/visits/${visitId}`, {
      scheduled_date: date,
      scheduled_time_begin: plannedCheckIn,
      scheduled_time_end: plannedCheckOut,
    });

    notification.success({
      message: 'Sucesso!',
      description: 'Edição da data e horário feita com sucesso!',
    });
    setIsModalOpen(false);
    const activeDays = yield select((state) => state.VisitsReducer.activeDays);
    yield call(fetchVisits, { activeDays, filters: null, forceFetch: true });
  } catch (e) {
    notification.error({
      message: 'Erro!',
      description: 'Ocorreu um erro ao mudar a data da visita. Tente novamente mais tarde...',
    });

    yield put(Creators.updateCheckDateAndHourError({ ...action.data }));
  }
}

export default [
  debounce(300, Types.FETCH_VISITS, fetchVisits),
  takeLatest(Types.FETCH_CURATION_FORM, fetchCurationFormSaga),
  takeEvery(Types.UPDATE_CHECK_DATE_AND_HOUR, updateVisitDate),
  takeEvery(Types.FETCH_JUSTIFICATION_OPTIONS, fetchJustificationOptions),
  takeEvery(Types.FETCH_EXPORT_FORMULARIES_OPTIONS, fetchFormulariesOptions),
];
