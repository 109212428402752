import {createReducer, createActions} from 'reduxsauce';

import * as actions from './reportsActions';

export const {Types, Creators} = createActions({
    fetchReports: ['activeDays', 'filters'],
    setReports: ['reports', 'date'],
    setSearch: ['search'],
    setCheckbox: ['checkbox'],
    setError: null,

    updateReportDate:['data'],
    updateReportDateSuccess: null,
    updateReportDateError: ['date'],

    fetchExportFormulariesOptions: null,
    fetchExportFormulariesOptionsSuccess: ['formulariesOptions'],
    fetchExportFormulariesOptionsError: null,
}, {prefix: 'reports/'});

const INITIAL_STATE = {
    reports: {},
    activeDays: [],
    lastUpdates: {},
    failedActions: [],
    checkbox: null,
    search: '',
    error: null,
    updatingReportsDate: false,

    formulariesOptions: [],
    loadingFormulariesOptions: false,
};

const HANDLERS = {
    [Types.FETCH_REPORTS]: actions.fetchReports,
    [Types.SET_REPORTS]: actions.setReports,
    [Types.SET_SEARCH]: actions.setSearch,
    [Types.SET_CHECKBOX]: actions.setCheckbox,
    [Types.SET_ERROR]: actions.setError,

    [Types.UPDATE_REPORT_DATE]: actions.updateReportDate,
    [Types.UPDATE_REPORT_DATE_SUCCESS]: actions.updateReportDateSuccess,
    [Types.UPDATE_REPORT_DATE_ERROR]: actions.updateReportDateError,

    [Types.FETCH_EXPORT_FORMULARIES_OPTIONS]: actions.fetchExportFormulariesOptions,
    [Types.FETCH_EXPORT_FORMULARIES_OPTIONS_SUCCESS]: actions.fetchExportFormulariesOptionsSuccess,
    [Types.FETCH_EXPORT_FORMULARIES_OPTIONS_ERROR]: actions.fetchExportFormulariesOptionsError,
};
 
export const path = 'ReportsReducer';

export default createReducer(INITIAL_STATE, HANDLERS);