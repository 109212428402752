import _ from 'lodash';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions(
  {
    setItemId: ['itemId', 'contentType'],

    syncItem: null,
    syncItemSuccess: ['store', 'forms', 'curationCategories'],
    syncItemError: null,

    syncForms: null,
    syncFormsSuccess: ['forms'],
    syncFormsError: null,

    setForm: ['form'],
    setFormOption: ['formOption'],

    syncAnswers: null,
    syncAnswersSuccess: ['answers', 'initialValues'],
    syncAnswersError: null,

    updateCheckDateAndHourSuccess: ['data'],
    updateCheckDateAndHour: ['data'],
    updateCheckDateAndHourError: null,

    fetchStoreToEdit: ['data'],
    fetchStoreToEditSuccess: ['data'],
    fetchStoreToEditError: [null],

    setCuration: ['id'],
    resetCurations: null,

    saveCuration: ['categories', 'comment', 'cleanCuration'],
    saveCurationFinish: null,

    saveAnswers: ['changeAnswers'],
    saveAnswersFinish: null,

    saveJustification: ['approved', 'comment', 'cleanJustification'],
    saveJustificationFinish: null,

    resetState: null,
  },
  { prefix: 'form/' }
);

export { Types };
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  itemId: null,
  contentType: 'visit',

  loadingItem: false,
  store: {},
  forms: [],
  visits: {},

  loadingForms: false,
  form: {},
  formOption: null,

  loadingAnswers: false,
  answers: [],

  loadingStore: false,
  updateDateSuccess: null,
  storeToEdit: {},

  curations: [],
  curationCategories: [],
  loadingSaveCuration: false,

  initialValues: {},

  loadingSaveAnswers: false,

  loadingSaveJustification: false,
});

/* Hookup Reducers to Types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ITEM_ID]: (state, { itemId, contentType }) => {
    return { ...state, itemId, contentType };
  },

  [Types.SYNC_ITEM]: (state) => {
    return {
      ...INITIAL_STATE,
      itemId: state.itemId,
      loadingItem: true,
      contentType: state.contentType,
    };
  },
  [Types.SYNC_ITEM_SUCCESS]: (state, { store, forms, curationCategories }) => {
    return { ...state, loadingItem: false, store, forms, curationCategories };
  },
  [Types.SYNC_ITEM_ERROR]: (state) => {
    return { ...state, loadingItem: false, store: {}, forms: [] };
  },

  [Types.UPDATE_CHECK_DATE_AND_HOUR]: (
    state,
    { plannedCheckIn, plannedCheckOut, visitId, date }
  ) => {
    return { ...state, loadingSaveDateAndHour: true };
  },
  [Types.UPDATE_CHECK_DATE_AND_HOUR_ERROR]: (state) => {
    return {
      ...state,
      loadingSaveDateAndHour: false,
    };
  },
  [Types.SYNC_FORMS]: (state) => {
    return { ...state, loadingForms: true };
  },
  [Types.SYNC_FORMS_SUCCESS]: (state, { forms }) => {
    return { ...state, loadingForms: false, forms };
  },
  [Types.SYNC_FORMS_ERROR]: (state) => {
    return { ...state, loadingForms: false, forms: [] };
  },

  [Types.FETCH_STORE_TO_EDIT]: (state, { visitId }) => {
    return { ...state, loadingStore: true };
  },

  [Types.FETCH_STORE_TO_EDIT_SUCCESS]: (state, storeToEdit) => {
    return { ...state, loadingStore: false, storeToEdit: storeToEdit.data };
  },

  [Types.FETCH_STORE_TO_EDIT_ERROR]: (state) => {
    return { ...state, loadingStore: false };
  },

  [Types.SET_FORM]: (state, { form }) => {
    return { ...state, form };
  },
  [Types.SET_FORM_OPTION]: (state, { formOption }) => {
    return { ...state, formOption };
  },

  [Types.SYNC_ANSWERS]: (state) => {
    return { ...state, loadingAnswers: true, answers: [], initialValues: {} };
  },
  [Types.SYNC_ANSWERS_SUCCESS]: (state, { answers, initialValues }) => {
    return { ...state, loadingAnswers: false, answers, initialValues };
  },
  [Types.SYNC_ANSWERS_ERROR]: (state) => {
    return { ...state, loadingAnswers: false, answers: [], initialValues: {} };
  },

  [Types.SET_CURATION]: (state, { id }) => {
    const index = state.curations.indexOf(id);

    if (index > -1) {
      const newCurations = state.curations;
      newCurations.splice(index, 1);
      return { ...state, curations: newCurations };
    }

    return { ...state, curations: [...state.curations, id] };
  },
  [Types.RESET_CURATIONS]: (state) => {
    return { ...state, curations: [] };
  },

  [Types.SAVE_CURATION]: (state) => {
    return { ...state, loadingSaveCuration: true };
  },
  [Types.SAVE_CURATION_FINISH]: (state) => {
    return { ...state, curations: [], loadingSaveCuration: false };
  },

  [Types.SAVE_ANSWERS]: (state) => {
    return { ...state, loadingSaveAnswers: true };
  },
  [Types.SAVE_ANSWERS_FINISH]: (state) => {
    return { ...state, loadingSaveAnswers: false };
  },

  [Types.SAVE_JUSTIFICATION]: (state) => {
    return { ...state, loadingSaveJustification: true };
  },
  [Types.SAVE_JUSTIFICATION_FINISH]: (state) => {
    return { ...state, loadingSaveJustification: false };
  },

  [Types.RESET_STATE]: (state) => ({ ...INITIAL_STATE }),
});

export const path = 'FormReducer';

/* Selectors */
export const getItemId = (state) => state[path].itemId;
export const getContentType = (state) => state[path].contentType;

export const loadingItem = (state) => state[path].loadingItem;
export const loadingForms = (state) => state[path].loadingForms;
export const emptyForms = (state) => state[path].forms.length <= 0;
export const getStore = (state) => state[path].store;
export const getForms = (state) => state[path].forms;
export const totalForms = (state) => state[path].forms.length;

export const getForm = (state) => state[path].form;
export const getFormOption = (state) => state[path].formOption;

export const getFormId = (state) => state[path].form.id;
export const getFormType = (state) => state[path].form.form_type;
export const getFormOptions = (state) => state[path].form.form_options;
export const getFormName = (state) => state[path].form.name;

export const getCurations = (state) => state[path].curations;
export const getCurationCategories = (state) => state[path].curationCategories;
export const loadingSaveCuration = (state) => state[path].loadingSaveCuration;

export const getStoreToEdit = (state) => state[path].storeToEdit;
export const getLoadingStore = (state) => state[path].loadingStore;

export const loadingAnswers = (state) => state[path].loadingAnswers;
export const getAnswers = (state) => state[path].answers;
export const getInitialValues = (state) => state[path].initialValues;

export const loadingSaveAnswers = (state) => state[path].loadingSaveAnswers;

export const loadingSaveJustification = (state) => state[path].loadingSaveJustification;
