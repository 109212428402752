/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-syntax */
import moment from 'moment';
import { buffers } from 'redux-saga';
import {
  debounce,
  call,
  put,
  actionChannel,
  take,
  fork,
  race,
  select,
  flush,
  delay
} from 'redux-saga/effects';

import api from '@/services/api';

import { Types, Creators } from './reducer';

function* fetchAndPopulate(dates, filters) {
  const { data } = yield call(api.get, `/user_list?`, { params: { dates, filters } });
  return data;
}

function* fetchUsers({ params }) {
  const { columns, currentFilters } = params;
  const startDate = yield select((state) => state.VisitsManagementWeeklyReducer.startDate);

  const formattedEndDate = columns[columns.length - 1].title;
  const formattedStartDate = moment(startDate).format('DD/MM/YYYY');

  const dateFilter = [formattedStartDate, formattedEndDate];

  try {
    const data = yield fetchAndPopulate(dateFilter, currentFilters);
    yield put(Creators.setUsers(data));
  } catch (e) {
    yield put(Creators.setError());
  }
}

function* watchRequests() {
  const requestChan = yield actionChannel(Types.FETCH_VISIT_META, buffers.sliding(15));
  while (true) {
    const action = yield take(requestChan);

    const { cancel } = yield race({
      task: call(fetchVisitMeta, action),
      cancel: take(Types.SET_DATE_FILTER || Types.STOP_REQUISITION),
    });

    yield delay(100)

    if (cancel !== undefined) {
      yield flush(requestChan);
    }
  }
}

function* fetchVisitMetaApi(dates, filters, userId) {
  const { data } = yield call(api.get, `/weekly_visits?`, {
    params: { dates, filters, user_id: userId.id },
  });
  return data;
}

function* fetchVisitMeta({ params }) {
  const { currentFilters, user } = params;
  const startDate = yield select((state) => state.VisitsManagementWeeklyReducer.startDate);

  const dates = [
    moment(startDate).format('DD/MM/YYYY'),
    moment(startDate)
      .add(7, 'days')
      .format('DD/MM/YYYY'),
  ];

  try {
    const data = yield fetchVisitMetaApi(dates, currentFilters, user);
    yield put(Creators.setVisitsMeta(user.id, data));
  } catch (e) {
    yield put(Creators.setError());
  }
}

function* fetchWeekApi(dates, filters) {
  const { data } = yield call(api.get, `/weekly_amount?`, {
    params: { dates, filters },
  });
  return data;
}

function* fetchWeek() {
  const startDate = yield select((state) => state.VisitsManagementWeeklyReducer.startDate);

  const dates = [
    moment(startDate).format('DD/MM/YYYY'),
    moment(startDate)
      .add(7, 'days')
      .format('DD/MM/YYYY'),
  ];

  try {
    const data = yield fetchWeekApi(dates);
    yield put(Creators.setWeekData(data));
  } catch (e) {
    yield put(Creators.setError());
  }
}

export default [
  debounce(500, Types.FETCH_USERS, fetchUsers),
  debounce(500, Types.FETCH_WEEK, fetchWeek),
  fork(watchRequests),
];
