import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  loginUser: ['data'],
  loginUserSuccess: ['token', 'role', 'userData'],
  loginUserFailed: ['error'],
  registerUser: null,
  registerUserSuccess: ['token', 'role', 'userData'],
  registerUserFailed: ['error'],
  logoutUser: null,
  logoutUserSuccess: null,
});

export { Types };
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: false,
  token: null,
  role: null,
  userData: {},
});

/* Hookup Reducers to Types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_USER]: (state) => ({ ...state, ...INITIAL_STATE, loading: true }),
  [Types.LOGIN_USER_SUCCESS]: (state, { token, role, userData }) => ({ ...state, token, role, userData, loading: false, error: false }),
  [Types.LOGIN_USER_FAILED]: (state, { error }) => ({ ...state, error, loading: false }),
  [Types.REGISTER_USER]: (state) => ({ ...state, ...INITIAL_STATE, loading: true }),
  [Types.REGISTER_USER_SUCCESS]: (state, { token, role, userData }) => ({ ...state, token, role, userData, loading: false, error: false }),
  [Types.REGISTER_USER_FAILED]: (state, { error }) => ({ ...state, error }),
  [Types.LOGOUT_USER]: (state) => ({ ...state, loadingLogout: true }),
  [Types.LOGOUT_USER_SUCCESS]: (state) => ({ ...state, ...INITIAL_STATE }),
});

/* Selectors */

export const isLoggedIn = state => !!state.auth.token;
export const isError = state => state.auth.error;
export const getRole = state => state.auth.role;
export const getToken = state => state.auth.token;
export const getPermissions = state => state.auth.userData.permissions;
export const getUserData = state => state.auth.userData;

