import _ from 'lodash';
import moment from 'moment';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions(
  {
    syncVisits: ['dates', 'filters'],
    syncVisitsSuccess: ['visits'],
    syncVisitsError: null,

    fetchJustificationOptions: null,
    fetchJustificationOptionsSuccess: ['justificationOptions'],
    fetchJustificationOptionsError: null,

    saveJustifications: ['data', 'callback'],
    saveJustificationsSuccess: null,
    saveJustificationsError: null,

    curationJustifications: ['data', 'callback'],
    curationJustificationsSuccess: null,
    curationJustificationsError: null,

    setJustificationIds: ['justificationIds'],
    setDates: ['dates'],

    setSearchJustifications: ['searchJustifications'],
    setFilterValues: ['filterValues'],

    resetStateVisits: null,

    setSelectedStatuses: ['selectedStatuses'],

    cancelJustifications: ['callback'],
    cancelJustificationsSuccess: null,
    cancelJustificationsError: null,
  },
  { prefix: 'JustificationsReducer/' }
);

export { Types };
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loadingVisits: false,
  visits: [],
  visit: {},
  dates: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
  filters: [],

  justificationIds: [],
  errorSaveJustifications: false,
  loadingSaveJustifications: false,

  justificationOptions: [],
  errorJustification: null,
  loadingJustification: false,

  formulariesOptions: [],
  loadingFormulariesOptions: false,

  loadingCurationJustifications: false,

  searchJustifications: '',

  filterOptions: [],
  filterValues: {},
  filterKeys: {
    status: { name: 'Status', group: 'general' },
    userParentName: { name: 'Nome do Supervisor', group: 'general' },
    userName: { name: 'Cargo', group: 'general' },
    LocalName: { name: 'Nome da Loja', group: 'general' },
  },

  selectedStatuses: [],

  loadingCancellation: false,
});

/* Hookup Reducers to Types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNC_VISITS]: (state, { filters }) => {
    return { ...state, justificationIds: [], filters, loadingVisits: true, selectedStatuses: [] };
  },
  [Types.SYNC_VISITS_SUCCESS]: (state, { visits }) => {
    return {
      ...state,
      visits,
      loadingVisits: false,
    };
  },
  [Types.SYNC_VISITS_ERROR]: (state) => {
    return { ...state, loadingVisits: false };
  },

  [Types.SET_SEARCH_JUSTIFICATIONS]: (state, { searchJustifications }) => {
    return { ...state, searchJustifications };
  },
  [Types.SET_FILTER_VALUES]: (state, { filterValues }) => {
    return { ...state, filterValues: _.pickBy(filterValues) };
  },
  [Types.SET_JUSTIFICATION_IDS]: (state, { justificationIds }) => {
    return { ...state, justificationIds };
  },
  [Types.SET_DATES]: (state, { dates }) => {
    return { ...state, dates };
  },

  [Types.FETCH_JUSTIFICATION_OPTIONS]: (state) => {
    return { ...state, loadingJustification: true, errorJustification: null };
  },

  [Types.FETCH_JUSTIFICATION_OPTIONS_SUCCESS]: (state, { justificationOptions }) => {
    return { ...state, justificationOptions, loadingJustification: false };
  },

  [Types.FETCH_JUSTIFICATION_OPTIONS_ERROR]: (state) => {
    return { ...state, errorJustification: true, loadingJustification: false };
  },

  [Types.SAVE_JUSTIFICATIONS]: (state) => {
    return { ...state, errorSaveJustifications: false, loadingSaveJustifications: true };
  },
  [Types.SAVE_JUSTIFICATIONS_SUCCESS]: (state) => {
    return { ...state, loadingSaveJustifications: false, errorSaveJustifications: false };
  },
  [Types.SAVE_JUSTIFICATIONS_ERROR]: (state) => {
    return { ...state, errorSaveJustifications: true, loadingSaveJustifications: false };
  },

  [Types.CURATION_JUSTIFICATIONS]: (state) => {
    return { ...state, loadingCurationJustifications: true };
  },
  [Types.CURATION_JUSTIFICATIONS_SUCCESS]: (state) => {
    return { ...state, loadingCurationJustifications: false };
  },
  [Types.CURATION_JUSTIFICATIONS_ERROR]: (state) => {
    return { ...state, loadingCurationJustifications: false };
  },

  [Types.SET_SELECTED_STATUSES]: (state, { selectedStatuses }) => {
    return { ...state, selectedStatuses}
  },

  [Types.CANCEL_JUSTIFICATIONS]: (state) => {
    return { ...state, loadingCancellation: true };
  },
  [Types.CANCEL_JUSTIFICATIONS_SUCCESS]: (state) => {
    return { ...state, loadingCancellation: false };
  },
  [Types.CANCEL_JUSTIFICATIONS_ERROR]: (state) => {
    return { ...state, loadingCancellation: false };
  },

  [Types.RESET_STATE_VISITS]: (state) => ({ ...INITIAL_STATE }),
});

export const PATH = 'JustificationsReducer';

const root = (selector) => {
  return (state) => state[PATH][selector];
};

/* Selectors */

export const getMain = {
  loadingVisits: (state) => state[PATH].loadingVisits,
  emptyVisits: (state) => state[PATH].visits <= 0,
  getVisits: (state) => state[PATH].visits,
  getVisit: (state, index) => state[PATH].visits[index],
  totalVisits: (state) => state[PATH].visits.length,
  getLoadingForm: (state) => state[PATH].loadingSendForm,
  getDates: (state) => state[PATH].dates,
  getFilters: (state) => state[PATH].filterValues,

  getSearchJustifications: (state) => state[PATH].searchJustifications,
  getJustificationIds: (state) => state[PATH].justificationIds,
  getErrorSaveJustifications: (state) => state[PATH].errorSaveJustifications,
  getLoadingSaveJustifications: (state) => state[PATH].loadingSaveJustifications,

  getJustificationOptions: (state) => state[PATH].justificationOptions,
  loadingJustification: (state) => state[PATH].loadingJustification,
  errorJustification: (state) => state[PATH].errorJustification,

  getSelectedStatuses: (state) => state[PATH].selectedStatuses,
  getLoadingCurationJustifications: (state) => state[PATH].loadingCurationJustifications,

  getLoadingCancellationJustifications: (state) => state[PATH].loadingCancellation,
};

export const filterKeys = (state) => state[PATH].filterKeys;
export const filterValues = (state) => state[PATH].filterValues;
