import { createReducer, createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchUsers: ['date', 'filters'],
  setUsers: ['users'],

  setDateFilter: ['date'],
  setSearchFilter: ['searchFilter'],
  clearSearchFilter: null,

  fetchVisitsForUser: ['userId', 'date'],
  setVisitsForUser: ['userId', 'date', 'visits'],
  clearLoading: ['userId', 'date'],
  clearAllLoadings: null,

  stopRequisition: [],

  setError: null,
}, {prefix: 'VisitManagementDailyReducer'});

const INITIAL_STATE = {
  loadingVisits: false,
  visits: {},
  searchFilter: null,
  date: null,
  users: [],
  lastUpdates: {},
  idsLoading: {},
  error: false,
};

const fetchUsers = (state) => {
  return { ...state, loadingVisits: true };
};

const setUsers = (state, { users }) => {
  return { ...state, loadingVisits: false, users };
};

const setError = (state) => {
  return { ...state, loadingVisits: false, error: true };
};

const setVisitsData = (state, { userId, date, visits }) => {
  return {
    ...state, visits: {
      ...state.visits,
      [`${userId}`]: {
        ...state.visits[`${userId}`],
        [`${date}`]: visits,
      }
    },
    lastUpdates: {
      ...state.lastUpdates,
      [`${userId}-${date}`]: new Date().getTime(),
    },
    idsLoading: {
      ...state.idsLoading,
      [`${userId}-${date}`]: false,
    }
  };
};

const setLoader = (state, { userId, date }) => {
  return {
    ...state,
    idsLoading: {
      ...state.idsLoading,
      [`${userId}-${date}`]: true,
    }
  };
};

const clearLoader = (state, { userId, date }) => {
  return {
    ...state,
    idsLoading: {
      ...state.idsLoading,
      [`${userId}-${date}`]: false,
    }
  };
};

const HANDLERS = {
  [Types.FETCH_USERS]: fetchUsers,
  [Types.SET_USERS]: setUsers,
  [Types.SET_ERROR]: setError,
  [Types.STOP_REQUISITION]: (state) => ({ ...state }),
  [Types.SET_DATE_FILTER]: (state, { date }) => ({ ...state, date }),
  [Types.SET_VISITS_FOR_USER]: setVisitsData,
  [Types.FETCH_VISITS_FOR_USER]: setLoader,
  [Types.CLEAR_LOADING]: clearLoader,
  [Types.CLEAR_ALL_LOADINGS]: (state) => ({ ...state, idsLoading: {} }),
  [Types.CLEAR_SEARCH_FILTER]: (state) => ({ ...state, searchFilter: null }),
  [Types.SET_SEARCH_FILTER]: (state, { searchFilter }) => ({ ...state, searchFilter }),
};

export default createReducer(INITIAL_STATE, HANDLERS);
