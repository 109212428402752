/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-syntax */
import { notification } from 'antd';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { buffers } from 'redux-saga';
import { debounce, call, put, select, actionChannel, take, race, flush, delay } from 'redux-saga/effects';

import api from '@/services/api';

import { Types, Creators } from './reducer';

function* fetchUsers(action) {
  try {
    const { filters, date } = action;

    const { status, data } = yield call(api, `/status_day/user_list`, { params: { filters, date } });

    if (status === 200 && data) {
      yield put(Creators.setUsers(data));
    }
  } catch (e) {

  }
}

function* watchFetchVisits() {
  const chan = yield actionChannel(Types.FETCH_VISITS_FOR_USER);

  while (true) {
    const { userId, date } = yield take(chan);

    const { task, cancel } = yield race({
      task: call(handleFetchVisits, userId, date),
      cancel: take([Types.SET_DATE_FILTER, Types.STOP_REQUISITION]),
    })

    yield delay(100)

    if (cancel !== undefined) {
      yield flush(chan)
    }

  }
}

function* handleFetchVisits(userId, date) {
  try {
    const lastUpdate = yield select((state) => (state.VisitsManagementDailyReducer.lastUpdates[`${userId}-${date}`]));

    if (!lastUpdate || (lastUpdate && new Date().getTime() - lastUpdate >= 120000)) {
      const { status, data } = yield call(api, `/daily_visits?date=${date}&user_id=${userId}`);

      if (status === 200) {
        const visits = data.length > 0 ? data[0] : {};
        yield put(Creators.setVisitsForUser(userId, date, visits));
      } else {
        yield put(Creators.clearLoading(userId, date));
      }

    } else {
      yield put(Creators.clearLoading(userId, date));
    }

  } catch (e) {

  }
}

export default [debounce(400, Types.FETCH_USERS, fetchUsers), watchFetchVisits()];
