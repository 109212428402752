import React from 'react';
import { Redirect } from 'react-router-dom';

import authRoutes from '@/routes/groups/auth.routes';
import pageRoutes from '@/routes/groups/page.routes';
import PrivateRoute from '@/routes/PrivateRoute';

const rootRoute = {
  path: '/',
  exact: true,
  key: 'root',
  name: 'root',
  label: 'root.title',
  component: () => <Redirect to="/visits" />,
  route: PrivateRoute,
};

const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

const authProtectedRoutes = pageRoutes.filter(route => route.label !== 'schedules.edit');

export { rootRoute, authRoutes, pageRoutes, authProtectedRoutes, flattenRoutes };
