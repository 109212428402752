/* eslint-disable import/no-cycle */
import React from 'react';

import { Tooltip } from 'antd';
import _ from 'lodash';
import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import requestError from '@/helpers/requestError';
import mockSchedules from '@/mocks/schedules.json';
import api from '@/services/api';

import Actions, { Types } from './reducer';

export function* syncSchedulesSagas(action) {
  // Mock Schedules
  if (process.env.REACT_APP_MOCK === "true") {
    yield put(Actions.syncSchedulesSuccess(mockSchedules.data));
    return yield call(updateFilterValuesSchedules, action);
  }

  try {
    const { data, status } = yield call(api.get, '/schedulers');

    if (status === 200) {
      yield put(Actions.syncSchedulesSuccess(data));

      if (data.length > 0) yield call(updateFilterValuesSchedules, action);
    } else {
      yield put(Actions.syncSchedulesError());
    }

  } catch (error) {
    requestError(error)
    yield put(Actions.syncSchedulesError());
  }
};

export function* filterSchedules(action) {
  const data = yield select(state => state.SchedulesReducer);
  const schedules = Array.from(Array(data.schedules.length).keys());

  if (!data.searchSchedules && _.isEmpty(data.filterValues)) {
    return yield put(Actions.setIndexedSchedules(schedules));
  }

  const indexedSchedules = [];

  schedules.forEach(scheduleIndex => {
    let validate = true;

    const schedule = data.schedules[scheduleIndex];

    if (data.searchSchedules && !schedule.name.toLowerCase().includes(data.searchSchedules.toLowerCase())) validate = false;

    if (!_.isEmpty(data.filterValues)) {
      Object.entries(data.filterValues).forEach(([filter, values]) => {
        if (!schedule[filter])  validate = false;
        else if (data.filterKeys[filter].group === 'general' && !values.includes(schedule[filter])) validate = false;
        else if (data.filterKeys[filter].group === 'general_array') {
          const checkValues = schedule[filter].map(item => !values.includes(item));
          if (checkValues.every(Boolean)) validate = false;
        }
      })
    }

    if (validate) indexedSchedules.push(scheduleIndex);
  });

  yield put(Actions.setIndexedSchedules(
    indexedSchedules
  ));
};

export function* updateFilterValuesSchedules(action) {
  const data = yield select(state => state.SchedulesReducer);

  const schedules = [];

  data.indexedSchedules.forEach(scheduleIndex => {
    schedules.push(data.schedules[scheduleIndex]);
  })

  const compare = (a, b) => {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  };

  const tooltipComponent = (text, count) => {
    if (text.length <= 18) return (`${text} (${count})`);

    return (
      <Tooltip title={text} placement='right'>
        {`${text.slice(0, 18)}... (${count})`}
      </Tooltip>
    );
  }

  const filters = Object.entries(data.filterKeys).map(([key, filter], index) => {
    let countKey = {};

    if (filter.group === 'general') {
      countKey = _.countBy(schedules, key)
    } else if (filter.group === 'general_array') {
      const allValues = _.reduce(schedules, (prev, item) => {
        if (!item[key]) return [...prev];

        return [...prev, ...item[key]]
      }, []);

      countKey = _.countBy(allValues)
    } else {
      countKey = _.countBy(schedules, (schedule) => schedule[filter.group][key])
    }

    if (Object.keys(countKey).filter(i => i !== 'undefined') <= 0) return null;

    const currentFilters = Object.keys(data.filterValues);

    if (currentFilters.length > 0 && currentFilters.includes(key) && data.filterOptions[index]) {
      const { items } = data.filterOptions[index];
      Object.keys(items).forEach(value => {
        if (!(value in countKey)) countKey[value] = items[value];
      })
    }

    return {
      key,
      items: countKey,
      name: filter.name,
      type: filter.type,
      options: _.compact(Object.keys(countKey)).map(value => (
        { label: tooltipComponent(value, countKey[value]), value }
      )).sort(compare)
    }
  });

  yield put(Actions.setFilterOptions(filters));
};

export default function* () {
  yield all([
    takeLatest(Types.SYNC_SCHEDULES, syncSchedulesSagas),
    takeLatest(Types.SET_SEARCH_SCHEDULES, filterSchedules),
    takeLatest(Types.SET_FILTER_SCHEDULES_VALUES, filterSchedules),
    takeLatest(Types.SET_INDEXED_SCHEDULES, updateFilterValuesSchedules),
  ]);
};
