import antdUs from 'antd/lib/locale-provider/en_US';

import menu from './menu';

const usLang = {
  messages: {
    ...menu,
  },
  antd: antdUs,
  locale: 'en-US',
};

export default usLang;
